/**
 * @generated SignedSource<<12bd07a821782734af936977eea18cdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type courseMenuItemsQuery$variables = {
  id: string;
};
export type courseMenuItemsQuery$data = {
  readonly courseMenuItems: {
    readonly collectionItems: ReadonlyArray<{
      readonly collectionId: number;
      readonly completedAt: any | null;
      readonly contentItems: ReadonlyArray<{
        readonly completedAt: any | null;
        readonly contentId: number;
        readonly index: number;
        readonly name: string;
        readonly startDate: any | null;
      } | null> | null;
      readonly index: number;
      readonly name: string;
      readonly startDate: any | null;
    } | null> | null;
    readonly completedAt: any | null;
    readonly id: string;
    readonly name: string;
    readonly nextCollectionId: number;
    readonly nextContentId: number;
    readonly percentCompleted: number;
    readonly startDate: any | null;
  };
};
export type courseMenuItemsQuery = {
  response: courseMenuItemsQuery$data;
  variables: courseMenuItemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "CourseMenuItems",
    "kind": "LinkedField",
    "name": "courseMenuItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nextContentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nextCollectionId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentCompleted",
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseCollectionItems",
        "kind": "LinkedField",
        "name": "collectionItems",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionId",
            "storageKey": null
          },
          (v1/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseCollectionContentItems",
            "kind": "LinkedField",
            "name": "contentItems",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentId",
                "storageKey": null
              },
              (v4/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "courseMenuItemsQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "courseMenuItemsQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "2c3a0409d583921effa268e0977aeca9",
    "id": null,
    "metadata": {},
    "name": "courseMenuItemsQuery",
    "operationKind": "query",
    "text": "query courseMenuItemsQuery(\n  $id: ID!\n) {\n  courseMenuItems(id: $id) {\n    id\n    name\n    nextContentId\n    nextCollectionId\n    percentCompleted\n    startDate\n    completedAt\n    collectionItems {\n      collectionId\n      name\n      index\n      startDate\n      completedAt\n      contentItems {\n        name\n        contentId\n        index\n        startDate\n        completedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "de40fb865a5e6a9d73dff842ef12aea0";

export default node;

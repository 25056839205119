/**
 * @generated SignedSource<<2a206153dbea385396d625e947611e64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type authFindAuthDomainExistsQuery$variables = {
  domain: string;
  skip: boolean;
};
export type authFindAuthDomainExistsQuery$data = {
  readonly findAuthDomainExists?: boolean;
};
export type authFindAuthDomainExistsQuery = {
  response: authFindAuthDomainExistsQuery$data;
  variables: authFindAuthDomainExistsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domain"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "domain",
            "variableName": "domain"
          }
        ],
        "kind": "ScalarField",
        "name": "findAuthDomainExists",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authFindAuthDomainExistsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authFindAuthDomainExistsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "20f888aacf4d7461e60ba5dacf2f9f39",
    "id": null,
    "metadata": {},
    "name": "authFindAuthDomainExistsQuery",
    "operationKind": "query",
    "text": "query authFindAuthDomainExistsQuery(\n  $domain: String!\n  $skip: Boolean!\n) {\n  findAuthDomainExists(domain: $domain) @skip(if: $skip)\n}\n"
  }
};
})();

(node as any).hash = "48e4d0a3cc7b6305d1785d72a9c7beeb";

export default node;

import { MatchedRoute, PreloadedRoute } from '../../types';
import { preloadLazyComponent } from 'suspendable';

export const preloadMatchedRoutes = (matchedRoutes: MatchedRoute[], search: string): PreloadedRoute[] =>
  matchedRoutes.map(({ route, match }) => {
    const { component, preloadData, fallback, authRequired } = route;
    const preloadedData = preloadData && preloadData(match.params, search);

    preloadLazyComponent(component);

    return {
      component,
      preloadedData,
      fallback,
      authRequired,
      match,
    };
  });

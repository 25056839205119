// Layout Types
import { lazyComponent } from 'suspendable';

import { ContentHomePreloadData } from 'app/pages/Courses/ContentHome/data';
import { CourseHomePreloadData } from 'app/pages/Courses/CourseHome/data';
import { DefaultPreloadData } from 'app/layouts/Default/data';

import { SettingsHomePreloadData } from './pages/Settings/SettingsHome/data';
import { SettingsUserGeneralPreloadData } from 'app/pages/Settings/SettingsUserGeneral/data';
import { SettingsAdminMembersPreloadData } from './pages/Settings/SettingsAdminMembers/data';

import { LoginPreloadData } from 'app/pages/AuthPages/Login/data';
import { SignInPreloadData } from 'app/pages/AuthPages/SignIn/data';
import { TeamInvitePreloadData } from 'app/pages/AuthPages/TeamInvite/data';
import { ResetPasswordPreloadData } from 'app/pages/AuthPages/ResetPassword/data';
import { SignUpInviteLinkPreloadData } from 'app/pages/AuthPages/SignUpInviteLink/data';

// Admin routes
export const adminRoutes = {
  members: '/admin/admin-members',
  setup: '/admin/admin-setup',
};

const routes = [
  {
    component: lazyComponent(() => import('app/layouts/Default'), { autoRetry: true }),
    preloadData: () => DefaultPreloadData(),
    routes: [
      /**
       * Auth
       */
      {
        path: '/sign-in',
        component: lazyComponent(() => import('app/pages/AuthPages/SignIn'), { autoRetry: true }),
        preloadData: SignInPreloadData,
        authRequired: false,
      },
      {
        path: '/login',
        component: lazyComponent(() => import('app/pages/AuthPages/Login'), { autoRetry: true }),
        preloadData: LoginPreloadData,
        authRequired: false,
      },
      {
        path: '/login/:typeId',
        component: lazyComponent(() => import('app/pages/AuthPages/Login'), { autoRetry: true }),
        preloadData: LoginPreloadData,
        authRequired: false,
      },
      {
        path: '/forgot-password',
        component: lazyComponent(() => import('app/pages/AuthPages/ForgotPassword'), { autoRetry: true }),
        authRequired: false,
      },
      {
        path: '/find-domain',
        component: lazyComponent(() => import('app/pages/AuthPages/FindDomain'), { autoRetry: true }),
        authRequired: false,
      },
      // KEEP FOR ADDING IN VERIFICATION LATER
      // {
      //   path: '/register/:token',
      //   component: lazyComponent(() => import('app/pages/AuthPages/Register'), { autoRetry: true }),
      //   preloadData: (params: { token: string }) => RegisterPreloadData(params),
      //   authRequired: false,
      // },
      {
        path: '/sign-up',
        component: lazyComponent(() => import('app/pages/AuthPages/Register'), { autoRetry: true }),
        // ADD BACK FOR EMAIL VERIFICATION
        // component: lazyComponent(() => import('app/pages/AuthPages/SignUp'), { autoRetry: true }),
        authRequired: false,
      },
      {
        path: '/reset-password',
        component: lazyComponent(() => import('app/pages/AuthPages/ResetPassword'), { autoRetry: true }),
        preloadData: ResetPasswordPreloadData,
        authRequired: false,
      },
      {
        path: '/teaminvite',
        component: lazyComponent(() => import('app/pages/AuthPages/TeamInvite'), { autoRetry: true }),
        preloadData: TeamInvitePreloadData,
        authRequired: false,
      },
      {
        path: '/:accountName/invite/:accountToken',
        component: lazyComponent(() => import('app/pages/AuthPages/SignUpInviteLink'), { autoRetry: true }),
        preloadData: params => SignUpInviteLinkPreloadData(params),
        authRequired: false,
      },
      /**
       * Courses
       */
      {
        path: '/course/:courseId',
        component: lazyComponent(() => import('app/pages/Courses/CourseHome'), { autoRetry: true }),
        preloadData: (params: { courseId: string }) => CourseHomePreloadData(params),
        authRequired: true,
      },
      {
        path: '/course/:courseId/collection/:collectionId/content/:contentId',
        component: lazyComponent(() => import('app/pages/Courses/ContentHome'), { autoRetry: true }),
        preloadData: (params: { courseId: string; contentId: string; collectionId: string }) =>
          ContentHomePreloadData(params),
        authRequired: true,
      },
      /**
       * Home
       */
      {
        path: '/',
        component: lazyComponent(() => import('app/pages/Courses/CourseHome'), { autoRetry: true }),
        preloadData: CourseHomePreloadData,
        authRequired: true,
      },
    ],
  },
  {
    component: lazyComponent(() => import('app/layouts/Settings'), { autoRetry: true }),
    preloadData: () => DefaultPreloadData(),
    routes: [
      /**
       * Settings
       */
      {
        path: '/admin/home',
        component: lazyComponent(() => import('app/pages/Settings/SettingsHome'), { autoRetry: true }),
        preloadData: SettingsHomePreloadData,
        authRequired: true,
      },
      {
        path: '/admin/user-general',
        component: lazyComponent(() => import('app/pages/Settings/SettingsUserGeneral'), { autoRetry: true }),
        preloadData: SettingsUserGeneralPreloadData,
        authRequired: true,
      },
      {
        path: adminRoutes.members,
        component: lazyComponent(() => import('app/pages/Settings/SettingsAdminMembers'), { autoRetry: true }),
        preloadData: SettingsAdminMembersPreloadData,
        authRequired: true,
      },
    ],
  },
  /**
   * 404
   */
  {
    component: lazyComponent(() => import('app/pages/Error'), { autoRetry: true }),
  },
];

export default routes;

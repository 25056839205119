import { MatchedRoute, Route } from '../../types';
import { matchPath } from '../matchPath';

export const matchRoutes = (routes: Route[], path: string): MatchedRoute[] => {
  const mainMatchedRoutes: MatchedRoute[] = [];

  for (let route of routes) {
    const match = matchPath(path, route);
    if (match) {
      const matchedRoutes: MatchedRoute[] = [
        {
          route,
          match,
        },
      ];

      if (route.routes) {
        const matchedChildRoutes = matchRoutes(route.routes, path);

        // if no matched routes, remove the parent
        if (matchedChildRoutes.length === 0) {
          matchedRoutes.pop();
        } else {
          // push child routes to main
          matchedRoutes.push(...matchedChildRoutes);
        }
      }

      mainMatchedRoutes.push(...matchedRoutes);
    }
  }

  return mainMatchedRoutes;
};

/**
 * @generated SignedSource<<b327f5052391ed3adc820089e9304334>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserForgotPasswordInput = {
  email: string;
};
export type authForgotUserPasswordMutation$variables = {
  input: UserForgotPasswordInput;
};
export type authForgotUserPasswordMutation$data = {
  readonly forgotUserPassword: boolean;
};
export type authForgotUserPasswordMutation = {
  response: authForgotUserPasswordMutation$data;
  variables: authForgotUserPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "forgotUserPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authForgotUserPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authForgotUserPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ed201d33792686ecfdc124d4d7bc1a3",
    "id": null,
    "metadata": {},
    "name": "authForgotUserPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation authForgotUserPasswordMutation(\n  $input: UserForgotPasswordInput!\n) {\n  forgotUserPassword(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "eca9f1c5cad7cc013992510b44546a7f";

export default node;

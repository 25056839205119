import graphql from 'babel-plugin-relay/macro';

/**
 * Fragments
 */
export const CONTENT_FRAGMENT = graphql`
  fragment contentFragment on Content {
    id
    name
    mediaType
    imageUrl
    mediaUrl
    externalId
    isVisible
    points
    length
    description
    # categories {
    #   ...categoryFragment
    # }
  }
`;

export const CONTENT_ANSWER_FRAGMENT = graphql`
  fragment contentAnswerFragment on UserCourseCollectionContent {
    answers {
      id
      type
      index
      label
      answer
      multipleChoiceAnswers {
        id
        index
        label
      }
      singleChoiceAnswer {
        id
        index
        label
      }
    }
  }
`;

export const CONTENT_INLINE_FRAGMENT = graphql`
  fragment contentInlineFragment on Content @inline {
    id
    name
    mediaType
    imageUrl
    mediaUrl
    externalId
    isVisible
    points
    length
    description
  }
`;

export const CONTENTS_PAGINATION_FRAGMENT = graphql`
  fragment contentsPaginationFragment on Query @refetchable(queryName: "ContentsRefetchQuery") {
    contents(first: $first, after: $after, filter: $filter, ordering: $ordering)
      @connection(key: "ContentsPaginationFragment_contents")
      @skip(if: $skip) {
      __id
      count
      edges {
        __id
        node {
          ...contentInlineFragment
          ...contentFragment
          # TODO MOVE THIS INTO A FRAGMENT
          collectionContents {
            userCourseCollectionContents {
              answers {
                id
                type
                label
                answer
                multipleChoiceAnswers {
                  id
                  label
                }
                singleChoiceAnswer {
                  id
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Queries
 */
export const CONTENTS_QUERY = graphql`
  query contentsQuery(
    $first: Int!
    $after: String
    $filter: ContentFilter
    $ordering: [ContentOrdering!]
    $skip: Boolean!
  ) {
    ...contentsPaginationFragment
  }
`;

export const CONTENT_USER_COURSE_COLLECTION_CONTENTS = graphql`
  query contentUserCourseCollectionContentsQuery($first: Int!, $filter: UserCourseCollectionContentFilter) {
    userCourseCollectionContents(first: $first, filter: $filter) {
      edges {
        node {
          ...contentAnswerFragment
        }
      }
    }
  }
`;

/*
 * TODO use a contentFragment, creatorContentFragment and questionFragment
 */
export const CONTENT_QUERY = graphql`
  query contentQuery($id: ID!) {
    content(id: $id) {
      id
      name
      mediaType
      externalId
      prismicContent
      imageUrl
      mediaUrl
      isVisible
      points
      createdAt
      updatedAt
      deletedAt
      length
      questions {
        id
        index
        type
        label
        choiceAnswers {
          id
          index
          isCorrect
        }
        answerFeedback {
          incorrect {
            body
            header
          }
          correct {
            body
            header
          }
        }
      }
      creator {
        id
        name
        profileImageUrl
        coverImageUrl
      }
      collectionContents {
        id
        index
        content {
          id
        }
        collection {
          id
        }
      }
    }
  }
`;

/**
 * @generated SignedSource<<c22c1148fea384846679181b5d196b87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type authFindAccountAuthDomainQuery$variables = {
  name: string;
  skip: boolean;
};
export type authFindAccountAuthDomainQuery$data = {
  readonly findAccountAuthDomain?: string | null;
};
export type authFindAccountAuthDomainQuery = {
  response: authFindAccountAuthDomainQuery$data;
  variables: authFindAccountAuthDomainQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ScalarField",
        "name": "findAccountAuthDomain",
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authFindAccountAuthDomainQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authFindAccountAuthDomainQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a10452ce5eff4e16e1c074dcbdc20628",
    "id": null,
    "metadata": {},
    "name": "authFindAccountAuthDomainQuery",
    "operationKind": "query",
    "text": "query authFindAccountAuthDomainQuery(\n  $name: String!\n  $skip: Boolean!\n) {\n  findAccountAuthDomain(name: $name) @skip(if: $skip)\n}\n"
  }
};
})();

(node as any).hash = "6b04981b8d83dd4dff43b08d0cca9fbf";

export default node;

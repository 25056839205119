import { loadQuery } from 'react-relay';
import RelayEnvironment from 'lib/utils/relay';
import { FIND_AUTH_DOMAIN_EXISTS_QUERY } from 'services/auth/auth.services';
import { authFindAuthDomainExistsQuery } from 'services/auth/__generated__/authFindAuthDomainExistsQuery.graphql';

export type SignInPreloadDataType = ReturnType<typeof SignInPreloadData>;

export const SignInPreloadData = () => {
  const { searchParams } = new URL(window.location.href);
  const domain = searchParams.get('domain') || '';

  return {
    domain,
    findAuthDomainExistsQueryRef: loadQuery<authFindAuthDomainExistsQuery>(
      RelayEnvironment,
      FIND_AUTH_DOMAIN_EXISTS_QUERY,
      {
        domain,
        skip: !domain,
      },
      { fetchPolicy: 'network-only' },
    ),
  };
};

import styled, { keyframes } from 'styled-components';
import { space, layout, flexbox } from 'styled-system';
import BgImage from 'assets/images/logos/pukaup_logo_round.png';
import { StyledProps } from 'types/styles';

interface Props extends StyledProps {
  color?: string;
  fontSize?: string | number;
  width?: number | string;
  height?: number | string;
  duration?: number;
}

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
  transform: scale(0.8);
  }
  100% {
  transform: scale(1);
  }
`;

export const Wrapper = styled.div<Props>`
  display: flex;
  ${space}
  ${layout}
  ${flexbox}
`;

export const LoaderWrapper = styled.div<Props>`
  height: 48px;
  width: 48px;
  background: url(${BgImage}) center center no-repeat;
  background-size: auto;
  background-size: 42px 42px;
  border-radius: 42px;
  animation: ${pulse} 1000ms linear infinite;
  margin: auto;
`;

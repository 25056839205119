/**
 * @generated SignedSource<<a975f21798506425166bc770bec66b1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserUpdateInput = {
  accountId?: string | null;
  dob?: string | null;
  email?: string | null;
  externalId?: string | null;
  firstName?: string | null;
  gender?: string | null;
  iP?: string | null;
  languages?: any | null;
  lastName?: string | null;
  phone?: string | null;
  photoUrls?: any | null;
  points?: number | null;
  setupComplete?: any | null;
  timeZone?: string | null;
  userRoleId?: string | null;
  workosDirUserRef?: string | null;
};
export type userUpdateUserMutation$variables = {
  id: string;
  input: UserUpdateInput;
};
export type userUpdateUserMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly firstName: string;
      readonly id: string;
      readonly lastName: string;
      readonly setupComplete: any | null;
      readonly userRole: {
        readonly id: string;
      };
    };
  };
};
export type userUpdateUserMutation = {
  response: userUpdateUserMutation$data;
  variables: userUpdateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserPayload",
    "kind": "LinkedField",
    "name": "updateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setupComplete",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserRole",
            "kind": "LinkedField",
            "name": "userRole",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userUpdateUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userUpdateUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "cb3e968f79f12ec7383db793ae9e6b4c",
    "id": null,
    "metadata": {},
    "name": "userUpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation userUpdateUserMutation(\n  $id: ID!\n  $input: UserUpdateInput!\n) {\n  updateUser(id: $id, input: $input) {\n    user {\n      id\n      firstName\n      lastName\n      setupComplete\n      userRole {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f562915fadc19d240a56e62301b4f085";

export default node;

/**
 * @generated SignedSource<<07668e1603d02927c74a896efce61ef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountByInviteTokenInput = {
  token: string;
};
export type accountByInviteTokenQuery$variables = {
  input: AccountByInviteTokenInput;
  skip: boolean;
};
export type accountByInviteTokenQuery$data = {
  readonly accountByInviteToken?: {
    readonly " $fragmentSpreads": FragmentRefs<"accountFragment">;
  } | null;
};
export type accountByInviteTokenQuery = {
  response: accountByInviteTokenQuery$data;
  variables: accountByInviteTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountByInviteTokenQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accountByInviteToken",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "accountFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountByInviteTokenQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accountByInviteToken",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "businessLogoUrls",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "bc9f00f489cdc730a9c0e4c177043e36",
    "id": null,
    "metadata": {},
    "name": "accountByInviteTokenQuery",
    "operationKind": "query",
    "text": "query accountByInviteTokenQuery(\n  $input: AccountByInviteTokenInput!\n  $skip: Boolean!\n) {\n  accountByInviteToken(input: $input) @skip(if: $skip) {\n    ...accountFragment\n    id\n  }\n}\n\nfragment accountFragment on Account {\n  id\n  name\n  businessLogoUrls\n  externalId\n}\n"
  }
};
})();

(node as any).hash = "373ab430d137911005653baff50e1049";

export default node;

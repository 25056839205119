/**
 * @generated SignedSource<<c765f103a52a73e3e913845a8b1f27a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountUpdateInput = {
  authAllowEmail?: boolean | null;
  authDomain?: string | null;
  authName?: string | null;
  authPreferredType?: string | null;
  businessLogoUrls?: any | null;
  externalId?: string | null;
  inviteLinkActive?: any | null;
  inviteLinkToken?: string | null;
  name?: string | null;
  setupComplete?: boolean | null;
  sponsorEmail?: string | null;
  sponsorName?: string | null;
  workosDetails?: any | null;
};
export type accountUpdateMutation$variables = {
  id: string;
  input: AccountUpdateInput;
};
export type accountUpdateMutation$data = {
  readonly updateAccount: {
    readonly account: {
      readonly id: string;
      readonly name: string;
    };
  };
};
export type accountUpdateMutation = {
  response: accountUpdateMutation$data;
  variables: accountUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AccountPayload",
    "kind": "LinkedField",
    "name": "updateAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "accountUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "accountUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54d18bf8509fd5d0782f24d1f507a094",
    "id": null,
    "metadata": {},
    "name": "accountUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation accountUpdateMutation(\n  $id: ID!\n  $input: AccountUpdateInput!\n) {\n  updateAccount(id: $id, input: $input) {\n    account {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f66f12c11b10b73b3dcd29c9b096bb00";

export default node;

/**
 * @generated SignedSource<<69a9f2e6101b0c287e84899c1c341b57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentQuestionType = "match_choice" | "multiple_choice" | "single_choice" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type contentAnswerFragment$data = {
  readonly answers: ReadonlyArray<{
    readonly answer: string | null;
    readonly id: string;
    readonly index: number;
    readonly label: string | null;
    readonly multipleChoiceAnswers: ReadonlyArray<{
      readonly id: string;
      readonly index: number;
      readonly label: string;
    } | null> | null;
    readonly singleChoiceAnswer: {
      readonly id: string;
      readonly index: number;
      readonly label: string;
    } | null;
    readonly type: ContentQuestionType;
  } | null> | null;
  readonly " $fragmentType": "contentAnswerFragment";
};
export type contentAnswerFragment$key = {
  readonly " $data"?: contentAnswerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentAnswerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentAnswerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserCourseCollectionContentAnswer",
      "kind": "LinkedField",
      "name": "answers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentQuestionChoice",
          "kind": "LinkedField",
          "name": "multipleChoiceAnswers",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentQuestionChoice",
          "kind": "LinkedField",
          "name": "singleChoiceAnswer",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserCourseCollectionContent",
  "abstractKey": null
};
})();

(node as any).hash = "3badd52f619ecce75c4cd2b33992d512";

export default node;

import theme from 'styles/theme';

export interface ReducerAction {
  name: SettingsKeys;
  value: string | number | undefined;
}

export interface NavbarConfig {
  backgroundColor: string;
  color: string;
  inverseHeight?: number;
}

export const navbarConfigInitial: NavbarConfig = {
  backgroundColor: theme.colors.whites[0],
  color: theme.colors.blacks[0],
  inverseHeight: undefined,
};

type SettingsKeys = keyof NavbarConfig;

export function navbarConfigReducer(state: NavbarConfig, action: ReducerAction) {
  return {
    ...state,
    [action.name]: action.value === 'default' ? undefined : action.value,
  };
}

import RelayEnvironment from 'lib/utils/relay';
import { getUserId, getAccountId } from 'lib/utils/authentication';

import { loadQuery } from 'react-relay';
import { USER_QUERY } from 'services/user/user.services';
import { ACCOUNT_USERS_QUERY } from 'services/account/account.services';
import { userQuery } from 'services/user/__generated__/userQuery.graphql';
import { accountUsersQuery } from 'services/account/__generated__/accountUsersQuery.graphql';
import { accountQuery } from 'services/account/__generated__/accountQuery.graphql';
import { accountUserInvitationsQuery } from 'services/account/__generated__/accountUserInvitationsQuery.graphql';
import { ACCOUNT_QUERY, ACCOUNT_USER_INVITATIONS_QUERY } from 'services/account/account.services';

export const SettingsAdminMembersPreloadData = () => {
  const userId = getUserId() ?? '';
  const accountId = getAccountId() ?? '';

  return {
    userQueryRef: loadQuery<userQuery>(
      RelayEnvironment,
      USER_QUERY,
      { id: userId ?? '', skip: !userId },
      { fetchPolicy: 'network-only' },
    ),
    accountUsersQueryRef: loadQuery<accountUsersQuery>(
      RelayEnvironment,
      ACCOUNT_USERS_QUERY,
      {
        first: 10,
        ordering: [{ sort: 'CREATED_AT', direction: 'DESC' }],
      },
      { fetchPolicy: 'store-or-network' },
    ),
    accountQueryRef: loadQuery<accountQuery>(
      RelayEnvironment,
      ACCOUNT_QUERY,
      { id: accountId ?? '', skip: !accountId },
      { fetchPolicy: 'store-and-network' },
    ),
    accountUserInvitationsQueryRef: loadQuery<accountUserInvitationsQuery>(
      RelayEnvironment,
      ACCOUNT_USER_INVITATIONS_QUERY,
      {
        first: 10,
        ordering: [{ sort: 'CREATED_AT', direction: 'DESC' }],
        filter: {
          where: {
            account: { id: { equalTo: accountId } },
            accepted: { isNull: true },
            deletedAt: { equalTo: null },
          },
        },
      },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type SettingsAdminMembersPreloadDataType = ReturnType<typeof SettingsAdminMembersPreloadData>;

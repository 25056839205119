/**
 * @generated SignedSource<<6914f3e7d38eb768f24e477cabf7cdba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserPassword = {
  newPassword: string;
  oldPassword: string;
};
export type authUpdateUserPasswordMutation$variables = {
  id: string;
  input: UpdateUserPassword;
};
export type authUpdateUserPasswordMutation$data = {
  readonly updateUserPassword: boolean;
};
export type authUpdateUserPasswordMutation = {
  response: authUpdateUserPasswordMutation$data;
  variables: authUpdateUserPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "updateUserPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authUpdateUserPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authUpdateUserPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4052a94a456af8486832be0cc3dfb9f",
    "id": null,
    "metadata": {},
    "name": "authUpdateUserPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation authUpdateUserPasswordMutation(\n  $id: ID!\n  $input: UpdateUserPassword!\n) {\n  updateUserPassword(id: $id, input: $input)\n}\n"
  }
};
})();

(node as any).hash = "c73696ae3d796bcbeec1a5746dae4c64";

export default node;

/**
 * @generated SignedSource<<a9a02410cc07a21009395782bfd54606>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type courseUserCoursesPaginationFragment$data = {
  readonly userCourses: {
    readonly __id: string;
    readonly count: number | null;
    readonly edges: ReadonlyArray<{
      readonly __id: string;
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"courseUserCoursesFragment">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "courseUserCoursesPaginationFragment";
};
export type courseUserCoursesPaginationFragment$key = {
  readonly " $data"?: courseUserCoursesPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"courseUserCoursesPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "userCourses"
],
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "ordering"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CoursesUserCoursesRefetchQuery.graphql')
    }
  },
  "name": "courseUserCoursesPaginationFragment",
  "selections": [
    {
      "alias": "userCourses",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "ordering",
          "variableName": "ordering"
        }
      ],
      "concreteType": "UserCourseConnection",
      "kind": "LinkedField",
      "name": "__CourseUserCoursesPaginationFragment_userCourses_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserCourseEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserCourse",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "courseUserCoursesFragment"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "79fa927a44e14aaff22860b557e910c0";

export default node;

/**
 * @generated SignedSource<<64624cba506ba3f2e812fe2a02462e40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userLevelTypeFragment$data = {
  readonly createdAt: any | null;
  readonly id: string;
  readonly index: number;
  readonly name: string;
  readonly pointsRequired: number | null;
  readonly updatedAt: any | null;
  readonly " $fragmentType": "userLevelTypeFragment";
};
export type userLevelTypeFragment$key = {
  readonly " $data"?: userLevelTypeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userLevelTypeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userLevelTypeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "index",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pointsRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "UserLevelType",
  "abstractKey": null
};

(node as any).hash = "b5f7059f23597867a5f6c54da1ac2d0c";

export default node;

import RelayEnvironment from 'lib/utils/relay';
import { loadQuery } from 'react-relay';
import { AUTH_VALIDATE_FORGOT_USER_PASSWORD_QUERY } from 'services/auth/auth.services';
import { authValidateForgotUserPasswordQuery } from 'services/auth/__generated__/authValidateForgotUserPasswordQuery.graphql';

export const ResetPasswordPreloadData = () => {
  const query = new URLSearchParams(window.location.search);
  const tokenValue = query.get('token');

  return {
    validateResetPasswordQueryRef: loadQuery<authValidateForgotUserPasswordQuery>(
      RelayEnvironment,
      AUTH_VALIDATE_FORGOT_USER_PASSWORD_QUERY,
      {
        input: {
          token: tokenValue ?? '',
        },
      },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type ResetPasswordPreloadDataType = ReturnType<typeof ResetPasswordPreloadData>;

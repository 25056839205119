import { getUserId } from 'lib/utils/authentication';
import RelayEnvironment from 'lib/utils/relay';
import { loadQuery } from 'react-relay';
import { CONTENTS_QUERY, CONTENT_QUERY } from 'services/content/content.services';
import { contentQuery } from 'services/content/__generated__/contentQuery.graphql';
import { contentsQuery } from 'services/content/__generated__/contentsQuery.graphql';
import { COURSE_MENU_ITEMS_QUERY } from 'services/course/course.services';
import { Params } from './types';

export const ContentHomePreloadData = (params: Params) => {
  const userId = getUserId();
  return {
    params,
    contentQueryRef: loadQuery<contentQuery>(
      RelayEnvironment,
      CONTENT_QUERY,
      {
        id: `content-${params.contentId}`,
      },
      { fetchPolicy: 'store-or-network' },
    ),
    contentsQueryRef: loadQuery<contentsQuery>(
      RelayEnvironment,
      CONTENTS_QUERY,
      {
        first: 1,
        filter: {
          where: {
            id: { equalTo: params.contentId },
            collectionContents: {
              collection: { id: { equalTo: params.collectionId } },
              userCourseCollectionContents: {
                user: {
                  id: { equalTo: userId },
                },
              },
            },
          },
        },
        skip: false,
        ordering: [{ sort: 'CREATED_AT', direction: 'DESC' }],
      },
      { fetchPolicy: 'network-only' },
    ),
    courseMenuItemsQueryRef: loadQuery<contentQuery>(
      RelayEnvironment,
      COURSE_MENU_ITEMS_QUERY,
      {
        id: params.courseId,
      },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type ContentHomePreloadDataType = ReturnType<typeof ContentHomePreloadData>;

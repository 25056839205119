import { loadQuery } from 'react-relay';
import RelayEnvironment from 'lib/utils/relay';
import { contentQuery } from 'services/content/__generated__/contentQuery.graphql';
import { COURSE_MENU_ITEMS_QUERY, COURSE_QUERY } from 'services/course/course.services';

type Params = {
  courseId: string;
};

export const CourseHomePreloadData = (params: Params) => {
  const courseId = params.courseId ?? `course-1`;

  return {
    courseQueryRef: loadQuery<contentQuery>(
      RelayEnvironment,
      COURSE_QUERY,
      {
        id: courseId,
      },
      { fetchPolicy: 'network-only' },
    ),
    courseMenuItemsQueryRef: loadQuery<contentQuery>(
      RelayEnvironment,
      COURSE_MENU_ITEMS_QUERY,
      {
        id: courseId,
      },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type CourseHomePreloadDataType = ReturnType<typeof CourseHomePreloadData>;

const TOKEN = 'token';

export const useAuthToken = () => {
  const setAuthToken = (authToken: string): void => {
    localStorage.setItem(TOKEN, authToken);
  };

  const removeAuthToken = (): void => {
    localStorage.removeItem(TOKEN);
  };

  const getAuthToken = (): string | null => {
    const token = localStorage.getItem(TOKEN);
    return token;
  };

  return { getAuthToken, setAuthToken, removeAuthToken };
};

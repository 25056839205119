/**
 * @generated SignedSource<<ffabf0c77a64f7593c4b5f32ffa8b896>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type courseFragment$data = {
  readonly categories: ReadonlyArray<{
    readonly name: string | null;
  }>;
  readonly collections: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"courseCollectionFragment">;
  }>;
  readonly creator: {
    readonly " $fragmentSpreads": FragmentRefs<"courseCreatorFragment">;
  };
  readonly description: string | null;
  readonly id: string;
  readonly imageUrl: string;
  readonly name: string | null;
  readonly " $fragmentType": "courseFragment";
};
export type courseFragment$key = {
  readonly " $data"?: courseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"courseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "courseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collections",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "courseCollectionFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Creator",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "courseCreatorFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();

(node as any).hash = "45d6b3045e55c9294e1dee702a3fa7d2";

export default node;

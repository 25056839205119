import RelayEnvironment from 'lib/utils/relay';

import { loadQuery } from 'react-relay/hooks';
import { ACCOUNT_BY_INVITE_TOKEN_QUERY } from 'services/account/account.services';
import { accountByInviteTokenQuery } from 'services/account/__generated__/accountByInviteTokenQuery.graphql';

interface Props {
  accountToken: string;
}

export const SignUpInviteLinkPreloadData = ({ accountToken }: Props) => {
  return {
    accountToken: accountToken ?? '',
    accountByInviteTokenQueryRef: loadQuery<accountByInviteTokenQuery>(
      RelayEnvironment,
      ACCOUNT_BY_INVITE_TOKEN_QUERY,
      { input: { token: accountToken }, skip: !accountToken },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type SignUpInviteLinkPreloadDataType = ReturnType<typeof SignUpInviteLinkPreloadData>;

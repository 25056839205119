/**
 * Track user and account events in google analytics
 */

import { TrackingEvents } from 'lib/constants/tracking';
import { useRouter } from 'lib/router';
import { useEffect } from 'react';

const trackUserId = (userId: string): void => {
  window.gtag('set', TrackingEvents.USER_ID, userId);
};
const trackAccountId = (accountId: string): void => {
  window.gtag('set', TrackingEvents.ACCOUNT_ID, accountId);
};

export const usePageTracking = () => {
  const router = useRouter();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: router.history.location.pathname + router.history.location.search + router.history.location.hash,
      page_search: router.history.location.search,
      page_hash: router.history.location.hash,
    });
  }, [router]);
};

export { trackUserId, trackAccountId };

/**
 *
 * AnalyticsIndex
 *
 */
import { memo, useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';

import { getUserId } from 'lib/utils/authentication';
import { usePathname } from 'lib/router';
import { useAuthToken } from 'hooks/useAuthToken/useAuthToken';
import useScript from 'hooks/useScript/useScript';

const PROD = process.env.NODE_ENV === 'production';

export const Analytics = memo(() => {
  const { href } = window.location;
  const pathname = usePathname();
  const pathRef = useRef(pathname);
  const { getAuthToken } = useAuthToken();
  const userId = getUserId();

  if (!PROD) return null;
  /* eslint-disable */

  const segmentKey = process.env.REACT_APP_SEGMENT_API_KEY;

  const [segmentLoaded] = useScript(`https://cdn.segment.com/analytics.js/v1/${segmentKey}/analytics.min.js`, {
    crossOrigin: true,
  });

  useEffect(() => {
    const token = getAuthToken();

    if (!segmentLoaded || !window.analytics) return;

    if (token) {
      const { role, email } = jwtDecode(token);

      if (window.analytics.identify) {
        window.analytics.identify(userId, {
          role,
          email,
        });
      }
    }
  }, [segmentLoaded]);

  useEffect(() => {
    if (!segmentLoaded || !window.analytics) return;

    const prevPath = pathRef.current;
    setTimeout(() => {
      const title = document.title || '';
      const pageName = title?.split(' - ')[1] ?? 'Uknown page';

      window.analytics.page(pageName, {
        referer: `${window.location.origin}${prevPath}`,
        title,
        path: pathname,
        url: href,
      });
    }, 200);
  }, [segmentLoaded, pathname, href]);

  return null;
});

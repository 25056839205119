import graphql from 'babel-plugin-relay/macro';

/**
 * Fragments
 */
export const USER_FRAGMENT = graphql`
  fragment userFragment on User {
    id
    firstName
    lastName
    photoUrls
    phone
    userRole {
      id
    }
    points
    setupComplete
  }
`;

export const USER_NAME_INLINE_FRAGMENT = graphql`
  fragment userNameInlineFragment on User @inline {
    id
    firstName
    lastName
    photoUrls
    phone
    userRole {
      id
    }
    points
    setupComplete
  }
`;

export const USER_NAME_FRAGMENT = graphql`
  fragment userNameFragment on User {
    id
    firstName
    lastName
    photoUrls
    phone
  }
`;

export const USER_LEVEL_TYPE_FRAGMENT = graphql`
  fragment userLevelTypeFragment on UserLevelType {
    id
    name
    index
    pointsRequired
    createdAt
    updatedAt
  }
`;

export const USER_LEVEL_TYPES_FRAGMENT = graphql`
  fragment userLevelTypesFragment on UserLevelType @relay(plural: true) {
    id
    name
    index
    pointsRequired
    createdAt
    updatedAt
  }
`;

export const USER_ACTIVITY_HISTORY_FRAGMENT = graphql`
  fragment userActivityHistoryFragment on UserActivityHistory {
    id
    user {
      ...userNameInlineFragment
    }
    activityType
    activityRef
    deletedAt
    createdAt
    updatedAt
  }
`;

export const USER_AUTHENTICATION_FRAGMENT = graphql`
  fragment userAuthenticationFragment on UserAuthentication @relay(plural: true) {
    id
    type
    username
    tokens
    deletedAt
    createdAt
    updatedAt
  }
`;

export const USERS_PAGINATION_FRAGMENT = graphql`
  fragment usersPaginationFragment on Query @refetchable(queryName: "UsersPaginationQuery") {
    users(first: $first, after: $after, ordering: $ordering, filter: $filter)
      @connection(key: "usersPaginationFragment_users") {
      __id
      count
      edges {
        __id
        node {
          ...userNameInlineFragment
          userRole {
            id
          }
          points
          setupComplete
        }
      }
    }
  }
`;

export const USER_ACCOUNT_USERS_PAGINATION_FRAGMENT = graphql`
  fragment userAccountUsersPaginationFragment on Query @refetchable(queryName: "userAccountUsersPaginationQuery") {
    accountUsers(first: $first, after: $after, ordering: $ordering)
      @connection(key: "UserAccountUsersPaginationFragment_accountUsers") {
      __id
      count
      edges {
        __id
        node {
          ...userNameInlineFragment
          ...userFragment
        }
      }
    }
  }
`;

/**
 * Queries
 */
export const USER_QUERY = graphql`
  query userQuery($id: ID!, $skip: Boolean!) {
    user(id: $id) @skip(if: $skip) {
      id
      email
      languages
      points
      setupComplete
      userRole {
        id
      }
      ...userNameInlineFragment
      ...userNameFragment
      account {
        ...accountFragment
      }
      userAuthentications {
        ...userAuthenticationFragment
      }
    }
  }
`;

export const USERS_QUERY = graphql`
  query usersQuery($first: Int!, $after: String, $ordering: [UserOrdering!], $filter: UserFilter) {
    ...usersPaginationFragment
  }
`;

// export const USER_LEVEL_TYPE_QUERY = graphql`
//   query userLevelTypeQuery($id: ID!) {
//     userLevelType(id: $id) {
//       id
//       index
//       pointsRequired
//     }
//   }
// `;

export const USER_LEVEL_HISTORIES_QUERY = graphql`
  query userLevelHistoriesQuery(
    $first: Int!
    $after: String
    $ordering: [UserLevelHistoryOrdering!]
    $filter: UserLevelHistoryFilter
    $skip: Boolean!
  ) {
    userLevelHistories(first: $first, after: $after, ordering: $ordering, filter: $filter) @skip(if: $skip) {
      edges {
        node {
          id
          userLevelType {
            ...userLevelTypeFragment
          }
          pointsRequired
        }
      }
    }
  }
`;

/**
 * Mutations
 */
export const UPDATE_USER_MUTATION = graphql`
  mutation userUpdateUserMutation($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      user {
        id
        firstName
        lastName
        setupComplete
        userRole {
          id
        }
      }
    }
  }
`;

export const USER_DELETE_MUTATION = graphql`
  mutation userDeleteUserMutation($id: ID!, $connections: [ID!]!) {
    deleteUser(id: $id) {
      id @deleteEdge(connections: $connections)
    }
  }
`;

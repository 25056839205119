/**
 * @generated SignedSource<<b50e569389c3d580b6f7087024d1f49e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type UserSort = "CREATED_AT" | "DELETED_AT" | "DOB" | "EMAIL" | "EXTERNAL_ID" | "FIRST_NAME" | "GENDER" | "ID" | "I_P" | "LANGUAGES" | "LAST_NAME" | "PHONE" | "PHOTO_URLS" | "POINTS" | "RANDOM" | "SETUP_COMPLETE" | "TIME_ZONE" | "UPDATED_AT" | "WORKOS_DIR_USER_REF" | "%future added value";
export type UserFilter = {
  search?: string | null;
  where?: UserWhere | null;
};
export type UserWhere = {
  account?: AccountWhere | null;
  and?: UserWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  dob?: StringFilter | null;
  email?: StringFilter | null;
  externalId?: IDFilter | null;
  firstName?: StringFilter | null;
  gender?: StringFilter | null;
  iP?: StringFilter | null;
  id?: IDFilter | null;
  languages?: any | null;
  lastName?: StringFilter | null;
  or?: UserWhere | null;
  phone?: StringFilter | null;
  photoUrls?: any | null;
  points?: IntFilter | null;
  setupComplete?: TimeFilter | null;
  timeZone?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  userActivityHistories?: UserActivityHistoryWhere | null;
  userAuthentications?: UserAuthenticationWhere | null;
  userCourseCollectionContents?: UserCourseCollectionContentWhere | null;
  userCourseCollections?: UserCourseCollectionWhere | null;
  userCourses?: UserCourseWhere | null;
  userLevelHistories?: UserLevelHistoryWhere | null;
  userLevels?: UserLevelWhere | null;
  userPointTransactions?: UserPointTransactionWhere | null;
  userRole?: UserRoleWhere | null;
  workosDirUserRef?: StringFilter | null;
};
export type IDFilter = {
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isNull?: boolean | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
};
export type StringFilter = {
  contain?: string | null;
  containStrict?: string | null;
  endWith?: string | null;
  endWithStrict?: string | null;
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isEmpty?: boolean | null;
  isNull?: boolean | null;
  isNullOrEmpty?: boolean | null;
  notContain?: string | null;
  notContainStrict?: string | null;
  notEmpty?: boolean | null;
  notEndWith?: string | null;
  notEndWithStrict?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
  notNullOrEmpty?: boolean | null;
  notStartWith?: string | null;
  notStartWithStrict?: string | null;
  startWith?: string | null;
  startWithStrict?: string | null;
};
export type IntFilter = {
  equalTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type TimeFilter = {
  equalTo?: any | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  moreThan?: any | null;
  moreThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notNull?: boolean | null;
};
export type AccountWhere = {
  accountCourseCollections?: AccountCourseCollectionWhere | null;
  accountCourses?: AccountCourseWhere | null;
  accountUserInvitations?: AccountUserInvitationWhere | null;
  and?: AccountWhere | null;
  authAllowEmail?: BooleanFilter | null;
  authDomain?: StringFilter | null;
  authName?: StringFilter | null;
  authPreferredType?: StringFilter | null;
  businessLogoUrls?: any | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  inviteLinkActive?: TimeFilter | null;
  inviteLinkToken?: StringFilter | null;
  name?: StringFilter | null;
  or?: AccountWhere | null;
  setupComplete?: BooleanFilter | null;
  sponsorEmail?: StringFilter | null;
  sponsorName?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  userActivityHistories?: UserActivityHistoryWhere | null;
  users?: UserWhere | null;
  workosDetails?: any | null;
};
export type BooleanFilter = {
  equalTo?: boolean | null;
  isNull?: boolean | null;
  notEqualTo?: boolean | null;
  notNull?: boolean | null;
};
export type AccountCourseWhere = {
  account?: AccountWhere | null;
  and?: AccountCourseWhere | null;
  collectionRelease?: StringFilter | null;
  course?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  endDate?: TimeFilter | null;
  endTime?: StringFilter | null;
  id?: IDFilter | null;
  or?: AccountCourseWhere | null;
  startDate?: TimeFilter | null;
  startTime?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type CourseWhere = {
  accountCourses?: AccountCourseWhere | null;
  and?: CourseWhere | null;
  categories?: CategoryWhere | null;
  collections?: CollectionWhere | null;
  createdAt?: TimeFilter | null;
  creator?: CreatorWhere | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  imageUrl?: StringFilter | null;
  isVisible?: TimeFilter | null;
  name?: StringFilter | null;
  or?: CourseWhere | null;
  updatedAt?: TimeFilter | null;
  userCourses?: UserCourseWhere | null;
};
export type CreatorWhere = {
  and?: CreatorWhere | null;
  collections?: CollectionWhere | null;
  contents?: ContentWhere | null;
  courses?: CourseWhere | null;
  coverImageUrl?: StringFilter | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  longDescription?: StringFilter | null;
  name?: StringFilter | null;
  or?: CreatorWhere | null;
  profileImageUrl?: StringFilter | null;
  shortDescription?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type CollectionWhere = {
  accountCourseCollections?: AccountCourseCollectionWhere | null;
  and?: CollectionWhere | null;
  categories?: CategoryWhere | null;
  collectionContents?: CollectionContentWhere | null;
  completeEmailTemplate?: StringFilter | null;
  course?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  creator?: CreatorWhere | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  emailTemplate?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  imageUrl?: StringFilter | null;
  index?: IntFilter | null;
  isVisible?: TimeFilter | null;
  mediaType?: StringFilter | null;
  name?: StringFilter | null;
  or?: CollectionWhere | null;
  points?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  userCourseCollections?: UserCourseCollectionWhere | null;
};
export type AccountCourseCollectionWhere = {
  account?: AccountWhere | null;
  and?: AccountCourseCollectionWhere | null;
  collection?: CollectionWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: AccountCourseCollectionWhere | null;
  startDate?: TimeFilter | null;
  startTime?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type CategoryWhere = {
  and?: CategoryWhere | null;
  collections?: CollectionWhere | null;
  contents?: ContentWhere | null;
  courses?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  id?: IDFilter | null;
  name?: StringFilter | null;
  or?: CategoryWhere | null;
  type?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type ContentWhere = {
  and?: ContentWhere | null;
  categories?: CategoryWhere | null;
  collectionContents?: CollectionContentWhere | null;
  createdAt?: TimeFilter | null;
  creator?: CreatorWhere | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  imageUrl?: StringFilter | null;
  isSingle?: BooleanFilter | null;
  isVisible?: TimeFilter | null;
  length?: IntFilter | null;
  mediaType?: StringFilter | null;
  mediaUrl?: StringFilter | null;
  name?: StringFilter | null;
  or?: ContentWhere | null;
  points?: IntFilter | null;
  updatedAt?: TimeFilter | null;
};
export type CollectionContentWhere = {
  and?: CollectionContentWhere | null;
  collection?: CollectionWhere | null;
  content?: ContentWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  index?: IntFilter | null;
  or?: CollectionContentWhere | null;
  updatedAt?: TimeFilter | null;
  userCourseCollectionContents?: UserCourseCollectionContentWhere | null;
};
export type UserCourseCollectionContentWhere = {
  and?: UserCourseCollectionContentWhere | null;
  answers?: any | null;
  collectionContent?: CollectionContentWhere | null;
  completedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  firstStartDate?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserCourseCollectionContentWhere | null;
  startDate?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserCourseCollectionWhere = {
  and?: UserCourseCollectionWhere | null;
  collection?: CollectionWhere | null;
  completedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  or?: UserCourseCollectionWhere | null;
  startDate?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserCourseWhere = {
  and?: UserCourseWhere | null;
  completedAt?: TimeFilter | null;
  course?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  or?: UserCourseWhere | null;
  startDate?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type AccountUserInvitationWhere = {
  accepted?: TimeFilter | null;
  account?: AccountWhere | null;
  and?: AccountUserInvitationWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  email?: StringFilter | null;
  expires?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  or?: AccountUserInvitationWhere | null;
  token?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type UserActivityHistoryWhere = {
  account?: AccountWhere | null;
  activityRef?: IntFilter | null;
  activityType?: StringFilter | null;
  and?: UserActivityHistoryWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserActivityHistoryWhere | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserRoleWhere = {
  and?: UserRoleWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  id?: IDFilter | null;
  or?: UserRoleWhere | null;
  updatedAt?: TimeFilter | null;
  users?: UserWhere | null;
};
export type UserAuthenticationWhere = {
  and?: UserAuthenticationWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserAuthenticationWhere | null;
  password?: StringFilter | null;
  providerIdpId?: IDFilter | null;
  tokens?: any | null;
  type?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
  username?: StringFilter | null;
};
export type UserLevelWhere = {
  and?: UserLevelWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserLevelWhere | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
  userLevelType?: UserLevelTypeWhere | null;
};
export type UserLevelTypeWhere = {
  and?: UserLevelTypeWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  index?: IntFilter | null;
  name?: StringFilter | null;
  or?: UserLevelTypeWhere | null;
  pointsRequired?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  userLevelHistories?: UserLevelHistoryWhere | null;
  userLevels?: UserLevelWhere | null;
};
export type UserLevelHistoryWhere = {
  and?: UserLevelHistoryWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserLevelHistoryWhere | null;
  pointsRequired?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
  userLevelType?: UserLevelTypeWhere | null;
};
export type UserPointTransactionWhere = {
  activityRef?: IntFilter | null;
  activityType?: StringFilter | null;
  and?: UserPointTransactionWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserPointTransactionWhere | null;
  points?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserOrdering = {
  direction: SortDirection;
  sort: UserSort;
};
export type UsersPaginationQuery$variables = {
  after?: string | null;
  filter?: UserFilter | null;
  first: number;
  ordering?: ReadonlyArray<UserOrdering> | null;
};
export type UsersPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"usersPaginationFragment">;
};
export type UsersPaginationQuery = {
  response: UsersPaginationQuery$data;
  variables: UsersPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ordering"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "ordering",
    "variableName": "ordering"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "usersPaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoUrls",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserRole",
                    "kind": "LinkedField",
                    "name": "userRole",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "points",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "setupComplete",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "ordering",
          "filter"
        ],
        "handle": "connection",
        "key": "usersPaginationFragment_users",
        "kind": "LinkedHandle",
        "name": "users"
      }
    ]
  },
  "params": {
    "cacheID": "116ecd1b015d27cf4570db7ba6fc3d06",
    "id": null,
    "metadata": {},
    "name": "UsersPaginationQuery",
    "operationKind": "query",
    "text": "query UsersPaginationQuery(\n  $after: String\n  $filter: UserFilter\n  $first: Int!\n  $ordering: [UserOrdering!]\n) {\n  ...usersPaginationFragment\n}\n\nfragment userNameInlineFragment on User {\n  id\n  firstName\n  lastName\n  photoUrls\n  phone\n  userRole {\n    id\n  }\n  points\n  setupComplete\n}\n\nfragment usersPaginationFragment on Query {\n  users(first: $first, after: $after, ordering: $ordering, filter: $filter) {\n    count\n    edges {\n      node {\n        ...userNameInlineFragment\n        userRole {\n          id\n        }\n        points\n        setupComplete\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f7a52718fcd0769649cf6e1672b294a";

export default node;

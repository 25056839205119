/**
 * @generated SignedSource<<86d23ad3b56e091b9bf27395418ca808>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type UserSort = "CREATED_AT" | "DELETED_AT" | "DOB" | "EMAIL" | "EXTERNAL_ID" | "FIRST_NAME" | "GENDER" | "ID" | "I_P" | "LANGUAGES" | "LAST_NAME" | "PHONE" | "PHOTO_URLS" | "POINTS" | "RANDOM" | "SETUP_COMPLETE" | "TIME_ZONE" | "UPDATED_AT" | "WORKOS_DIR_USER_REF" | "%future added value";
export type UserOrdering = {
  direction: SortDirection;
  sort: UserSort;
};
export type userAccountUsersPaginationQuery$variables = {
  after?: string | null;
  first: number;
  ordering?: ReadonlyArray<UserOrdering> | null;
};
export type userAccountUsersPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"userAccountUsersPaginationFragment">;
};
export type userAccountUsersPaginationQuery = {
  response: userAccountUsersPaginationQuery$data;
  variables: userAccountUsersPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ordering"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "ordering",
    "variableName": "ordering"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "userAccountUsersPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "userAccountUsersPaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "userAccountUsersPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "accountUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "photoUrls",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserRole",
                    "kind": "LinkedField",
                    "name": "userRole",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "points",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "setupComplete",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "ordering"
        ],
        "handle": "connection",
        "key": "UserAccountUsersPaginationFragment_accountUsers",
        "kind": "LinkedHandle",
        "name": "accountUsers"
      }
    ]
  },
  "params": {
    "cacheID": "f622f3b4c89411ee5d20ccf0957a4589",
    "id": null,
    "metadata": {},
    "name": "userAccountUsersPaginationQuery",
    "operationKind": "query",
    "text": "query userAccountUsersPaginationQuery(\n  $after: String\n  $first: Int!\n  $ordering: [UserOrdering!]\n) {\n  ...userAccountUsersPaginationFragment\n}\n\nfragment userAccountUsersPaginationFragment on Query {\n  accountUsers(first: $first, after: $after, ordering: $ordering) {\n    count\n    edges {\n      node {\n        ...userNameInlineFragment\n        ...userFragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment userFragment on User {\n  id\n  firstName\n  lastName\n  photoUrls\n  phone\n  userRole {\n    id\n  }\n  points\n  setupComplete\n}\n\nfragment userNameInlineFragment on User {\n  id\n  firstName\n  lastName\n  photoUrls\n  phone\n  userRole {\n    id\n  }\n  points\n  setupComplete\n}\n"
  }
};
})();

(node as any).hash = "ac157115beb9c0c242a46b662fa6f4f4";

export default node;

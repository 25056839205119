import { colors } from './colors';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  breakpoints: ['48em', '64em', '80em', '96em', '120em'],
  colors,
  fontSizes: [12, 15, 16, 18, 20, 22, 24, 28, 32, 36, 48],
  fontWeights: [300, 400, 500, 600, 700],
  lineHeights: ['15px', '22px', '33px'],
  space: [0, 4, 8, 12, 16, 20, 24, 32, 36, 48, 64, 80, 128, 256, 512],
  spaceV2: [0, 2, 4, 8, 12, 16, 20, 24, 32, 36, 48, 64, 80, 128, 256, 512],
  radii: [0, 4, 8, 12, 16, 24, 36, 64, 128, 256, 512],
  radiiv2: [0, 2, 4, 6, 8, 12, 16, 24, 36, 64, 128, 256, 512],
  pageWidth: [1060],
  shadows: {
    base: '0px 0px 6px 0px rgba(0,0,0,0.08)',
    dropdown: '0px 0px 3px 1px rgba(207, 203, 195, 0.25), 0px 0px 15px rgba(207, 203, 195, 0.25)',
    input: 'inset 0px 0px 0px 1px rgba(39, 46, 43, 0.6)',
    inputInset: 'inset 0 0 0 1px #375844',
    inputErrorInset: `inset 0 0 0 1px #98453d`,
  },
  zIndices: [0, 1, 2, 3, 4, 5],
  palette: {
    primary: colors.blacks[0],
  },
};

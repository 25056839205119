export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum MEDIA_TYPE {
  AUDIO = 'audio',
  VIDEO = 'video',
  TEXT = 'text',
  MULTI = 'multi',
}

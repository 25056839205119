import React, { createContext, useContext, useReducer, useState, Dispatch } from 'react';
import { navbarConfigReducer, navbarConfigInitial, ReducerAction, NavbarConfig } from './reducers';

export interface StateContextType {
  showConfetti: boolean;
  setShowConfetti: Dispatch<boolean>;
  navbarConfig: NavbarConfig;
  dispatchNavbarConfig: Dispatch<ReducerAction>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [navbarConfig, dispatchNavbarConfig] = useReducer(navbarConfigReducer, navbarConfigInitial);

  let contextValue = {
    showConfetti,
    setShowConfetti,
    navbarConfig,
    dispatchNavbarConfig,
  } as StateContextType;

  const { children } = props;

  return <StateContext.Provider value={{ ...contextValue }}>{children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

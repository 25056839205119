/**
 * @generated SignedSource<<8ae02cf7df6599f239949578b3bc956a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HandleVerifyUserEmailInput = {
  email: string;
};
export type authHandleVerifyUserEmailMutation$variables = {
  input: HandleVerifyUserEmailInput;
};
export type authHandleVerifyUserEmailMutation$data = {
  readonly handleVerifyUserEmail: boolean;
};
export type authHandleVerifyUserEmailMutation = {
  response: authHandleVerifyUserEmailMutation$data;
  variables: authHandleVerifyUserEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "handleVerifyUserEmail",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authHandleVerifyUserEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authHandleVerifyUserEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "81e5e93ee6bda8cff63d8fbbabdb6b6b",
    "id": null,
    "metadata": {},
    "name": "authHandleVerifyUserEmailMutation",
    "operationKind": "mutation",
    "text": "mutation authHandleVerifyUserEmailMutation(\n  $input: HandleVerifyUserEmailInput!\n) {\n  handleVerifyUserEmail(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "7c1d4f508007b298ac326804fa5d6b6c";

export default node;

import { useRouter } from 'lib/router';
import React, { FC, ReactNode } from 'react';
import { PreloadedRoute } from '../../../types';
import { Redirect } from '../../Redirect';

interface Props {
  preloadedRoute: PreloadedRoute;
  children: ReactNode;
}

export const PreloadedRouteRenderer: FC<Props> = ({ preloadedRoute, children }) => {
  const { history } = useRouter();
  const searchQuery = history.location;
  const { component: Component, preloadedData, match, authRequired } = preloadedRoute;
  const hasAuth = () => localStorage.token || false;

  if (authRequired && !hasAuth()) return <Redirect to="/login" />;
  return (
    <Component preloadedData={preloadedData} params={match.params} searchQuery={searchQuery}>
      {children}
    </Component>
  );
};

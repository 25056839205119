import RelayEnvironment from 'lib/utils/relay';
import { getUserId } from 'lib/utils/authentication';

import { loadQuery } from 'react-relay';
import { USER_QUERY } from 'services/user/user.services';
import { userQuery } from 'services/user/__generated__/userQuery.graphql';
// import { countryCountriesQuery } from 'services/country-look/__generated__/countryCountriesQuery.graphql';
// import { COUNTRIES_QUERY } from 'services/country-look/country.services';

export const SettingsUserGeneralPreloadData = () => {
  const userId: string | null = getUserId();
  return {
    userQueryRef: loadQuery<userQuery>(
      RelayEnvironment,
      USER_QUERY,
      { id: userId ?? '', skip: !userId },
      { fetchPolicy: 'network-only' },
    ),
    // countriesQueryRef: loadQuery<countryCountriesQuery>(
    //   RelayEnvironment,
    //   COUNTRIES_QUERY,
    //   {
    //     first: 1000,
    //   },
    //   { fetchPolicy: 'store-and-network' },
    // ),
  };
};

export type SettingsUserGeneralPreloadDataType = ReturnType<typeof SettingsUserGeneralPreloadData>;

/**
 * @generated SignedSource<<6f1a9e5014151112b2986b7ee7e00d30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValidateInviteUserInput = {
  token: string;
};
export type authValidateInviteUserQuery$variables = {
  input: ValidateInviteUserInput;
  skip: boolean;
};
export type authValidateInviteUserQuery$data = {
  readonly validateInviteUser?: {
    readonly accepted: any | null;
    readonly account: {
      readonly id: string;
    };
    readonly email: string;
    readonly id: string;
    readonly token: string;
  } | null;
};
export type authValidateInviteUserQuery = {
  response: authValidateInviteUserQuery$data;
  variables: authValidateInviteUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "AccountUserInvitation",
        "kind": "LinkedField",
        "name": "validateInviteUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accepted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authValidateInviteUserQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authValidateInviteUserQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6c9de21d3d43a856a13708a848f27abc",
    "id": null,
    "metadata": {},
    "name": "authValidateInviteUserQuery",
    "operationKind": "query",
    "text": "query authValidateInviteUserQuery(\n  $input: ValidateInviteUserInput!\n  $skip: Boolean!\n) {\n  validateInviteUser(input: $input) @skip(if: $skip) {\n    id\n    accepted\n    token\n    email\n    account {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "af3cd6e3de290fa56083c6070c6bf2fa";

export default node;

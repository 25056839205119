/**
 * @generated SignedSource<<3a18bd1ab3775aa3a5ef9c543ff785ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type courseUserCoursesFragment$data = {
  readonly completedAt: any | null;
  readonly course: {
    readonly categories: ReadonlyArray<{
      readonly name: string | null;
    }>;
    readonly description: string | null;
    readonly id: string;
    readonly imageUrl: string;
    readonly name: string | null;
  };
  readonly id: string;
  readonly percentCompleted: number;
  readonly startDate: any | null;
  readonly " $fragmentType": "courseUserCoursesFragment";
};
export type courseUserCoursesFragment$key = {
  readonly " $data"?: courseUserCoursesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"courseUserCoursesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "courseUserCoursesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Course",
      "kind": "LinkedField",
      "name": "course",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserCourse",
  "abstractKey": null
};
})();

(node as any).hash = "8cad59355cdb83bc8525b44f4b44dd70";

export default node;

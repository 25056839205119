/**
 * @generated SignedSource<<1d7fd97bb58795def843d796e40bb417>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userNameInlineFragment$data = {
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly phone: string | null;
  readonly photoUrls: any | null;
  readonly points: number;
  readonly setupComplete: any | null;
  readonly userRole: {
    readonly id: string;
  };
  readonly " $fragmentType": "userNameInlineFragment";
};
export type userNameInlineFragment$key = {
  readonly " $data"?: userNameInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userNameInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userNameInlineFragment"
};

(node as any).hash = "2dc24099a7acf97fdf11aec0652b58d5";

export default node;

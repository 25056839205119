import RelayEnvironment from 'lib/utils/relay';
import { loadQuery } from 'react-relay';
import { userQuery } from 'services/user/__generated__/userQuery.graphql';
import { USER_QUERY } from 'services/user/user.services';
import { getUserId } from 'lib/utils/authentication';

export const SettingsHomePreloadData = props => {
  const userId: string = getUserId() ?? '';
  return {
    userQueryRef: loadQuery<userQuery>(
      RelayEnvironment,
      USER_QUERY,
      { id: userId ?? '', skip: !userId },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type SettingsHomePreloadDataType = ReturnType<typeof SettingsHomePreloadData>;

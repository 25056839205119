import graphql from 'babel-plugin-relay/macro';

/**
 * Queries
 */
export const AUTH_VALIDATE_FORGOT_USER_PASSWORD_QUERY = graphql`
  query authValidateForgotUserPasswordQuery($input: ValidateForgotUserPasswordInput!) {
    validateForgotUserPassword(input: $input)
  }
`;

export const AUTH_VALIDATE_INVITE_USER_QUERY = graphql`
  query authValidateInviteUserQuery($input: ValidateInviteUserInput!, $skip: Boolean!) {
    validateInviteUser(input: $input) @skip(if: $skip) {
      id
      accepted
      token
      email
      account {
        id
      }
    }
  }
`;

export const FIND_ACCOUNT_AUTH_DOMAIN_QUERY = graphql`
  query authFindAccountAuthDomainQuery($name: String!, $skip: Boolean!) {
    findAccountAuthDomain(name: $name) @skip(if: $skip)
  }
`;

export const FIND_AUTH_DOMAIN_EXISTS_QUERY = graphql`
  query authFindAuthDomainExistsQuery($domain: String!, $skip: Boolean!) {
    findAuthDomainExists(domain: $domain) @skip(if: $skip)
  }
`;

export const FIND_ACCOUNT_AUTH_METHODS_QUERY = graphql`
  query authFindAccountAuthMethodsQuery($input: FindAccountAuthMethodsInput!) {
    findAccountAuthMethods(input: $input) {
      accountName
      preferredType
      methods {
        name
        authorizationURL
      }
    }
  }
`;

export const AUTH_VALIDATE_VERIFY_USER_EMAIL_QUERY = graphql`
  query authValidateVerifyUserEmailQuery($input: ValidateVerifyUserEmailInput!, $skip: Boolean!) {
    validateVerifyUserEmail(input: $input) @skip(if: $skip) {
      email
    }
  }
`;

/**
 * Mutations
 */
export const FORGOT_USER_PASSWORD_MUTATION = graphql`
  mutation authForgotUserPasswordMutation($input: UserForgotPasswordInput!) {
    forgotUserPassword(input: $input)
  }
`;

export const AUTH_REGISTER_USER_WITH_TOKEN = graphql`
  mutation authRegisterUserWithTokenMutation($input: UserRegisterWithTokenInput!) {
    registerUserWithToken(input: $input) {
      accessToken
    }
  }
`;

export const AUTH_UPDATE_USER_PASSWORD_MUTATION = graphql`
  mutation authUpdateUserPasswordMutation($id: ID!, $input: UpdateUserPassword!) {
    updateUserPassword(id: $id, input: $input)
  }
`;

export const AUTH_RESET_USER_PASSWORD_MUTATION = graphql`
  mutation authResetUserPasswordMutation($input: UserResetPasswordInput!) {
    resetUserPassword(input: $input) {
      accessToken
    }
  }
`;

export const FORGOT_USER_AUTH_NAME_MUTATION = graphql`
  mutation authForgotUserAuthNameMutation($input: UserForgotAuthNameInput!) {
    forgotUserAuthName(input: $input)
  }
`;

export const AUTH_HANDLE_VERIFY_EMAIL_MUTATION = graphql`
  mutation authHandleVerifyUserEmailMutation($input: HandleVerifyUserEmailInput!) {
    handleVerifyUserEmail(input: $input)
  }
`;

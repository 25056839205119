/**
 *
 * LoaderPukaUp
 *
 */
import React, { memo } from 'react';
import { Wrapper, LoaderWrapper } from './styles';

interface Props {
  /** Sets the continer width. Default 100%. */
  containerWidth?: string | number;
  /** Sets the continer height. Default 100%. */
  containerHeight?: string | number;
  /** Sets the continer alignItems. Default center. */
  containerFlexAlign?: string;
  /** Sets the continer justifyContetn. Default center. */
  containerFlexJustify?: string;
}

export const LoaderPukaUp = memo(
  ({ containerWidth, containerHeight, containerFlexAlign, containerFlexJustify }: Props) => {
    return (
      <Wrapper
        width={containerWidth ?? '100%'}
        height={containerHeight ?? '100%'}
        alignItems={containerFlexAlign ?? 'center'}
        justifyContent={containerFlexJustify ?? 'center'}
      >
        <LoaderWrapper />
      </Wrapper>
    );
  },
);

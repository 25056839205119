import axios from 'axios';
// import * as Sentry from '@sentry/react';
// const { localStorage } = global.window;

// create an axios instance
export const publicService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});

export const getRequestId = (): string => Math.random().toString(36).substr(2, 9);

publicService.interceptors.request.use(
  config => {
    const requestId = getRequestId();
    // Sentry.configureScope(scope => {
    //   scope.setTag('x_request_id', requestId);
    // });
    config.headers['x-request-id'] = requestId;
    // const authToken = localStorage.token;
    // if (authToken || config.url !== '/login') {
    //   const configOptions = config;
    //   configOptions.headers.Authorization = `Bearer ${authToken}`;
    // }
    return config;
  },
  error => Promise.reject(error),
);

publicService.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    const { status } = response;

    // if the custom code is not 20000, it is judged as an error.
    if (status !== 200) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })

      // 401: Illegal token; 400: Other clients logged in; 403: Token expired;
      if (status === 401 || status === 400 || status === 403) {
        // to re-login
        // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        //   confirmButtonText: 'Re-Login',
        //   cancelButtonText: 'Cancel',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
      }
      return Promise.reject(new Error(res.message || 'Error'));
    }
    return res;
  },
  error =>
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    Promise.reject(error),
);

/**
 * @generated SignedSource<<149541c73432e0396cfa0f2f18a61a3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCourseCollectionContentFilter = {
  search?: string | null;
  where?: UserCourseCollectionContentWhere | null;
};
export type UserCourseCollectionContentWhere = {
  and?: UserCourseCollectionContentWhere | null;
  answers?: any | null;
  collectionContent?: CollectionContentWhere | null;
  completedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  firstStartDate?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserCourseCollectionContentWhere | null;
  startDate?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type IDFilter = {
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isNull?: boolean | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
};
export type TimeFilter = {
  equalTo?: any | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  moreThan?: any | null;
  moreThanOrEqualTo?: any | null;
  notEqualTo?: any | null;
  notNull?: boolean | null;
};
export type CollectionContentWhere = {
  and?: CollectionContentWhere | null;
  collection?: CollectionWhere | null;
  content?: ContentWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  index?: IntFilter | null;
  or?: CollectionContentWhere | null;
  updatedAt?: TimeFilter | null;
  userCourseCollectionContents?: UserCourseCollectionContentWhere | null;
};
export type IntFilter = {
  equalTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type CollectionWhere = {
  accountCourseCollections?: AccountCourseCollectionWhere | null;
  and?: CollectionWhere | null;
  categories?: CategoryWhere | null;
  collectionContents?: CollectionContentWhere | null;
  completeEmailTemplate?: StringFilter | null;
  course?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  creator?: CreatorWhere | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  emailTemplate?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  imageUrl?: StringFilter | null;
  index?: IntFilter | null;
  isVisible?: TimeFilter | null;
  mediaType?: StringFilter | null;
  name?: StringFilter | null;
  or?: CollectionWhere | null;
  points?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  userCourseCollections?: UserCourseCollectionWhere | null;
};
export type StringFilter = {
  contain?: string | null;
  containStrict?: string | null;
  endWith?: string | null;
  endWithStrict?: string | null;
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isEmpty?: boolean | null;
  isNull?: boolean | null;
  isNullOrEmpty?: boolean | null;
  notContain?: string | null;
  notContainStrict?: string | null;
  notEmpty?: boolean | null;
  notEndWith?: string | null;
  notEndWithStrict?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
  notNullOrEmpty?: boolean | null;
  notStartWith?: string | null;
  notStartWithStrict?: string | null;
  startWith?: string | null;
  startWithStrict?: string | null;
};
export type CourseWhere = {
  accountCourses?: AccountCourseWhere | null;
  and?: CourseWhere | null;
  categories?: CategoryWhere | null;
  collections?: CollectionWhere | null;
  createdAt?: TimeFilter | null;
  creator?: CreatorWhere | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  imageUrl?: StringFilter | null;
  isVisible?: TimeFilter | null;
  name?: StringFilter | null;
  or?: CourseWhere | null;
  updatedAt?: TimeFilter | null;
  userCourses?: UserCourseWhere | null;
};
export type CreatorWhere = {
  and?: CreatorWhere | null;
  collections?: CollectionWhere | null;
  contents?: ContentWhere | null;
  courses?: CourseWhere | null;
  coverImageUrl?: StringFilter | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  longDescription?: StringFilter | null;
  name?: StringFilter | null;
  or?: CreatorWhere | null;
  profileImageUrl?: StringFilter | null;
  shortDescription?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type ContentWhere = {
  and?: ContentWhere | null;
  categories?: CategoryWhere | null;
  collectionContents?: CollectionContentWhere | null;
  createdAt?: TimeFilter | null;
  creator?: CreatorWhere | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  imageUrl?: StringFilter | null;
  isSingle?: BooleanFilter | null;
  isVisible?: TimeFilter | null;
  length?: IntFilter | null;
  mediaType?: StringFilter | null;
  mediaUrl?: StringFilter | null;
  name?: StringFilter | null;
  or?: ContentWhere | null;
  points?: IntFilter | null;
  updatedAt?: TimeFilter | null;
};
export type BooleanFilter = {
  equalTo?: boolean | null;
  isNull?: boolean | null;
  notEqualTo?: boolean | null;
  notNull?: boolean | null;
};
export type CategoryWhere = {
  and?: CategoryWhere | null;
  collections?: CollectionWhere | null;
  contents?: ContentWhere | null;
  courses?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  id?: IDFilter | null;
  name?: StringFilter | null;
  or?: CategoryWhere | null;
  type?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type AccountCourseWhere = {
  account?: AccountWhere | null;
  and?: AccountCourseWhere | null;
  collectionRelease?: StringFilter | null;
  course?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  endDate?: TimeFilter | null;
  endTime?: StringFilter | null;
  id?: IDFilter | null;
  or?: AccountCourseWhere | null;
  startDate?: TimeFilter | null;
  startTime?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type AccountWhere = {
  accountCourseCollections?: AccountCourseCollectionWhere | null;
  accountCourses?: AccountCourseWhere | null;
  accountUserInvitations?: AccountUserInvitationWhere | null;
  and?: AccountWhere | null;
  authAllowEmail?: BooleanFilter | null;
  authDomain?: StringFilter | null;
  authName?: StringFilter | null;
  authPreferredType?: StringFilter | null;
  businessLogoUrls?: any | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  inviteLinkActive?: TimeFilter | null;
  inviteLinkToken?: StringFilter | null;
  name?: StringFilter | null;
  or?: AccountWhere | null;
  setupComplete?: BooleanFilter | null;
  sponsorEmail?: StringFilter | null;
  sponsorName?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  userActivityHistories?: UserActivityHistoryWhere | null;
  users?: UserWhere | null;
  workosDetails?: any | null;
};
export type AccountCourseCollectionWhere = {
  account?: AccountWhere | null;
  and?: AccountCourseCollectionWhere | null;
  collection?: CollectionWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: AccountCourseCollectionWhere | null;
  startDate?: TimeFilter | null;
  startTime?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type AccountUserInvitationWhere = {
  accepted?: TimeFilter | null;
  account?: AccountWhere | null;
  and?: AccountUserInvitationWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  email?: StringFilter | null;
  expires?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  or?: AccountUserInvitationWhere | null;
  token?: StringFilter | null;
  updatedAt?: TimeFilter | null;
};
export type UserWhere = {
  account?: AccountWhere | null;
  and?: UserWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  dob?: StringFilter | null;
  email?: StringFilter | null;
  externalId?: IDFilter | null;
  firstName?: StringFilter | null;
  gender?: StringFilter | null;
  iP?: StringFilter | null;
  id?: IDFilter | null;
  languages?: any | null;
  lastName?: StringFilter | null;
  or?: UserWhere | null;
  phone?: StringFilter | null;
  photoUrls?: any | null;
  points?: IntFilter | null;
  setupComplete?: TimeFilter | null;
  timeZone?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  userActivityHistories?: UserActivityHistoryWhere | null;
  userAuthentications?: UserAuthenticationWhere | null;
  userCourseCollectionContents?: UserCourseCollectionContentWhere | null;
  userCourseCollections?: UserCourseCollectionWhere | null;
  userCourses?: UserCourseWhere | null;
  userLevelHistories?: UserLevelHistoryWhere | null;
  userLevels?: UserLevelWhere | null;
  userPointTransactions?: UserPointTransactionWhere | null;
  userRole?: UserRoleWhere | null;
  workosDirUserRef?: StringFilter | null;
};
export type UserRoleWhere = {
  and?: UserRoleWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  description?: StringFilter | null;
  id?: IDFilter | null;
  or?: UserRoleWhere | null;
  updatedAt?: TimeFilter | null;
  users?: UserWhere | null;
};
export type UserActivityHistoryWhere = {
  account?: AccountWhere | null;
  activityRef?: IntFilter | null;
  activityType?: StringFilter | null;
  and?: UserActivityHistoryWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserActivityHistoryWhere | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserAuthenticationWhere = {
  and?: UserAuthenticationWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserAuthenticationWhere | null;
  password?: StringFilter | null;
  providerIdpId?: IDFilter | null;
  tokens?: any | null;
  type?: StringFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
  username?: StringFilter | null;
};
export type UserCourseWhere = {
  and?: UserCourseWhere | null;
  completedAt?: TimeFilter | null;
  course?: CourseWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  or?: UserCourseWhere | null;
  startDate?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserCourseCollectionWhere = {
  and?: UserCourseCollectionWhere | null;
  collection?: CollectionWhere | null;
  completedAt?: TimeFilter | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  or?: UserCourseCollectionWhere | null;
  startDate?: TimeFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type UserLevelWhere = {
  and?: UserLevelWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserLevelWhere | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
  userLevelType?: UserLevelTypeWhere | null;
};
export type UserLevelTypeWhere = {
  and?: UserLevelTypeWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  index?: IntFilter | null;
  name?: StringFilter | null;
  or?: UserLevelTypeWhere | null;
  pointsRequired?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  userLevelHistories?: UserLevelHistoryWhere | null;
  userLevels?: UserLevelWhere | null;
};
export type UserLevelHistoryWhere = {
  and?: UserLevelHistoryWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserLevelHistoryWhere | null;
  pointsRequired?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
  userLevelType?: UserLevelTypeWhere | null;
};
export type UserPointTransactionWhere = {
  activityRef?: IntFilter | null;
  activityType?: StringFilter | null;
  and?: UserPointTransactionWhere | null;
  createdAt?: TimeFilter | null;
  deletedAt?: TimeFilter | null;
  id?: IDFilter | null;
  or?: UserPointTransactionWhere | null;
  points?: IntFilter | null;
  updatedAt?: TimeFilter | null;
  user?: UserWhere | null;
};
export type contentUserCourseCollectionContentsQuery$variables = {
  filter?: UserCourseCollectionContentFilter | null;
  first: number;
};
export type contentUserCourseCollectionContentsQuery$data = {
  readonly userCourseCollectionContents: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"contentAnswerFragment">;
      } | null;
    } | null> | null;
  };
};
export type contentUserCourseCollectionContentsQuery = {
  response: contentUserCourseCollectionContentsQuery$data;
  variables: contentUserCourseCollectionContentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "contentUserCourseCollectionContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserCourseCollectionContentConnection",
        "kind": "LinkedField",
        "name": "userCourseCollectionContents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCourseCollectionContentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserCourseCollectionContent",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "contentAnswerFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "contentUserCourseCollectionContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserCourseCollectionContentConnection",
        "kind": "LinkedField",
        "name": "userCourseCollectionContents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCourseCollectionContentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserCourseCollectionContent",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserCourseCollectionContentAnswer",
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentQuestionChoice",
                        "kind": "LinkedField",
                        "name": "multipleChoiceAnswers",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentQuestionChoice",
                        "kind": "LinkedField",
                        "name": "singleChoiceAnswer",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7679d88fc87002358856e2acec8c82bb",
    "id": null,
    "metadata": {},
    "name": "contentUserCourseCollectionContentsQuery",
    "operationKind": "query",
    "text": "query contentUserCourseCollectionContentsQuery(\n  $first: Int!\n  $filter: UserCourseCollectionContentFilter\n) {\n  userCourseCollectionContents(first: $first, filter: $filter) {\n    edges {\n      node {\n        ...contentAnswerFragment\n        id\n      }\n    }\n  }\n}\n\nfragment contentAnswerFragment on UserCourseCollectionContent {\n  answers {\n    id\n    type\n    index\n    label\n    answer\n    multipleChoiceAnswers {\n      id\n      index\n      label\n    }\n    singleChoiceAnswer {\n      id\n      index\n      label\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bead348e95bf009e1633fbd1fb5be79";

export default node;

/* eslint-disable indent */
/**
 * Auth Utils
 */
import jwtDecode from 'jwt-decode';
import { Redirect } from 'lib/router';
import { publicService } from './request';
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface Global {
      window: any;
      localStorage: {
        getItem: (key: string) => any;
        setItem: (key: string, value: string) => any;
      };
    }
  }
}

type LoginUserProps = {
  // User's email
  email: string;
  // User's password
  password: string;
  // Redirect the user on success to this link
  redirectUrl?: string;
  // Code from slack to link the account
  // to the Slack profile
  slackToken?: string;
};

type LoginInputDataType = {
  email: string;
  password: string;
};

type RegisterInputDataType = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  accountId?: string;
  role: 'ADMIN' | 'USER' | 'GUEST';
  timeZone?: string;
};

type RegisterUserWithToken = {
  firstName: string;
  lastName: string;
  password: string;
  token: string;
  email: string;
  timeZone?: string;
  type: 'INVITATION' | 'ACCOUNT_LINK';
};

type ResetPasswordInputDataType = {
  password: string;
  token: string;
};

const resetUserPassword = async (newPassword, inputToken) => {
  try {
    const inputData: ResetPasswordInputDataType = {
      password: newPassword,
      token: inputToken,
    };

    const resetUserPasswordQuery = `mutation resetUserPassword {
      resetUserPassword(input: ${JSON.stringify(inputData).replace(/"([^(")"]+)":/g, '$1:')}) {
        accessToken
      }
    }`;

    const {
      data: {
        resetUserPassword: { accessToken },
      },
    } = await publicService('/graphql', {
      method: 'post',
      data: {
        query: resetUserPasswordQuery,
      },
    });
    await setLoginToken({ token: accessToken }, false, '/');
    return { success: true, error: '' };
  } catch (error) {
    return { success: false, error: 'An error was encountered during the password reset operation' };
  }
};

const setLoginToken = async ({ token }, noRedirect: boolean, redirectUrl?: string) => {
  window.localStorage.setItem('token', token);

  if (!noRedirect) {
    if (redirectUrl) {
      window.open(redirectUrl, '_self');
    } else {
      window.open('/', '_self');
      Redirect({ to: '/' });
    }
  }
};

const loginUser = async ({ email, password, redirectUrl, slackToken }: LoginUserProps) => {
  try {
    const inputData: LoginInputDataType = {
      email,
      password,
    };

    const handleLink = `mutation loginUser {
      loginUser(input: ${JSON.stringify(inputData)
        .replace(/"([^(")"]+)":/g, '$1:')
        .replace('"USER"', 'USER')
        .replace('"ACCOUNT"', 'ACCOUNT')}) {
        accessToken
      }
    }`;

    const {
      data: {
        loginUser: { accessToken },
      },
    } = await publicService('/graphql', {
      method: 'post',
      data: {
        query: handleLink,
      },
    });
    await setLoginToken({ token: accessToken }, false, '/');
    return { success: true, error: null };
  } catch (error) {
    return { success: false, error: 'The email address or password you entered is incorrect' };
  }
};

const registerUser = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  role: 'ADMIN' | 'USER' | 'GUEST',
  accountId?: string,
) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    const inputData: RegisterInputDataType = {
      email,
      password,
      firstName,
      lastName,
      role,
    };

    if (accountId) inputData.accountId = accountId;
    if (timeZone) inputData.timeZone = timeZone;

    const handleLink = `mutation registerUser {
       registerUser(input: ${JSON.stringify(inputData)
         .replace(/"([^(")"]+)":/g, '$1:')
         .replace(/role.*?,/, `role: ${role.replace('"', '')},`)
         .replace('"USER"', 'USER')
         .replace('"ACCOUNT"', 'ACCOUNT')}) {
         accessToken
       }
     }`;

    const res: any = await publicService('/graphql', {
      method: 'post',
      data: {
        query: handleLink,
      },
    });

    if (res?.data?.registerUser?.accessToken) {
      await setLoginToken({ token: res.data.registerUser.accessToken }, false, '/');
      return { success: true, error: '' };
    } else if (res?.errors) {
      return { success: false, error: res.errors[0]?.message };
    } else {
      return { success: false, error: 'Unable to signup with this email / password' };
    }
  } catch (error) {
    return { success: false, error: 'Something went wrong please try again later' };
  }
};

const registerUserWithToken = async ({
  firstName,
  lastName,
  password,
  token,
  email,
  type,
  timeZone,
}: RegisterUserWithToken) => {
  const inputData = {
    firstName,
    lastName,
    password,
    token,
    email,
    timeZone,
    type,
  };

  try {
    const res: any = await publicService('/graphql', {
      method: 'post',
      data: {
        query: `mutation registerUserWithToken {
          registerUserWithToken(input: ${JSON.stringify(inputData)
            .replace(/"([^(")"]+)":/g, '$1:')
            .replace(`"ACCOUNT_LINK"`, `ACCOUNT_LINK`)
            .replace(`"INVITATION"`, `INVITATION`)})
            {
              accessToken
            }
        }`,
      },
    });

    if (res?.data?.registerUserWithToken?.accessToken) {
      await setLoginToken({ token: res.data.registerUserWithToken.accessToken }, false, '/');
      return { success: true, error: '' };
    } else if (res?.errors) {
      return { success: false, error: res.errors[0]?.message };
    } else {
      return { success: false, error: 'Unable to signup with this email / password' };
    }
  } catch (error) {
    return { success: false, error: 'Something went wrong please try again later' };
  }
};

const getAuthState = () => {
  const token: string | null = window.localStorage.getItem('token');

  if (token) {
    return { token };
  }
  return null;
};

const logoutUser = async () => {
  try {
    const data = await publicService('/graphql', {
      method: 'post',
      data: {
        query: `mutation logoutUser { logoutUser }`,
      },
    });
    if (data) {
      window.localStorage.removeItem('token');
      window.localStorage.setItem('logout', Date.now().toString());
      window.open('/login', '_self');
      return true;
    }
  } catch (error) {
    console.log('error', error);
  }
};

const getCurrentPath = originalUrl => {
  if (typeof window === 'object') {
    return window.location.host;
  }
  return originalUrl;
};

const getUserId: () => string | null = () => {
  const token: string | null = window.localStorage.getItem('token');

  if (token) {
    const { user_id: userId } = jwtDecode(token);
    return userId as string;
  }
  return null;
};

const getUserRole: () => string | null = () => {
  const token: string | null = window.localStorage.getItem('token');

  if (token) {
    const { role } = jwtDecode(token);
    return role as string;
  }
  return null;
};

const getAccountId: () => string | null = () => {
  const token: string | null = window.localStorage.getItem('token');

  if (token) {
    const { account_id: accountId } = jwtDecode(token);
    return accountId as string;
  }
  return null;
};

const convertID = (id: string): string => {
  const w = id.split('-');
  return w[1];
};

export {
  registerUser,
  registerUserWithToken,
  loginUser,
  logoutUser,
  getCurrentPath,
  getUserId,
  getUserRole,
  getAccountId,
  setLoginToken,
  resetUserPassword,
  getAuthState,
  convertID,
};

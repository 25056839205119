/**
 * @generated SignedSource<<2c095c47599f8b585ef5a257a8f7c865>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserResetPasswordInput = {
  password: string;
  token: string;
};
export type authResetUserPasswordMutation$variables = {
  input: UserResetPasswordInput;
};
export type authResetUserPasswordMutation$data = {
  readonly resetUserPassword: {
    readonly accessToken: string;
  } | null;
};
export type authResetUserPasswordMutation = {
  response: authResetUserPasswordMutation$data;
  variables: authResetUserPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginResponse",
    "kind": "LinkedField",
    "name": "resetUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authResetUserPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authResetUserPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c7895f69ad15884cb8fd5f83861d534",
    "id": null,
    "metadata": {},
    "name": "authResetUserPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation authResetUserPasswordMutation(\n  $input: UserResetPasswordInput!\n) {\n  resetUserPassword(input: $input) {\n    accessToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "393773e2f1e965750c38015ee431d862";

export default node;

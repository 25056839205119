/**
 *
 * App
 *
 */
import { ToastContainer } from 'react-toastify';
import AppStateProvider from 'state';

import { createRouter, BaseRouter } from 'lib/router';

// import { LoaderDots } from './components/LoaderDots';
import { LoaderPukaUp } from './components/LoaderPukaUp';
import routes from './routes';

import { GlobalStyle } from 'styles/global-styles';
import 'react-day-picker/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';

const router = createRouter(routes as any, {
  defaultFallback: <LoaderPukaUp />,
});

const App = () => {
  /**
   * Return
   */
  return (
    <AppStateProvider>
      <BaseRouter router={router} />
      <GlobalStyle />
      <ToastContainer hideProgressBar autoClose={5000} />
    </AppStateProvider>
  );
};

export default App;

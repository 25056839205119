/**
 * @generated SignedSource<<1bf299de70c084dcfbac6a5b0af0c81d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentQuestionType = "match_choice" | "multiple_choice" | "single_choice" | "text" | "%future added value";
export type UpdateUserCourseInput = {
  answers?: ReadonlyArray<UserCourseCollectionContentAnswerInput | null> | null;
  collectionId: string;
  contentId: string;
  courseId: string;
};
export type UserCourseCollectionContentAnswerInput = {
  answer?: string | null;
  id: string;
  index: number;
  label?: string | null;
  matchChoiceAnswer?: ReadonlyArray<ChoiceAnswerInput | null> | null;
  multipleChoiceAnswers?: ReadonlyArray<ChoiceAnswerInput | null> | null;
  singleChoiceAnswer?: ChoiceAnswerInput | null;
  type: ContentQuestionType;
};
export type ChoiceAnswerInput = {
  id: string;
  index: number;
  label: string;
  matchChoiceAnswer?: ChoiceAnswerInput | null;
};
export type courseUserCourseUpdateMutation$variables = {
  input: UpdateUserCourseInput;
};
export type courseUserCourseUpdateMutation$data = {
  readonly userCourseUpdate: {
    readonly collectionItems: ReadonlyArray<{
      readonly collectionId: number;
      readonly completedAt: any | null;
      readonly contentItems: ReadonlyArray<{
        readonly completedAt: any | null;
        readonly contentId: number;
        readonly index: number;
        readonly name: string;
        readonly startDate: any | null;
      } | null> | null;
      readonly index: number;
      readonly name: string;
      readonly startDate: any | null;
    } | null> | null;
    readonly completedAt: any | null;
    readonly id: string;
    readonly name: string;
    readonly startDate: any | null;
  };
};
export type courseUserCourseUpdateMutation = {
  response: courseUserCourseUpdateMutation$data;
  variables: courseUserCourseUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CourseMenuItems",
    "kind": "LinkedField",
    "name": "userCourseUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CourseCollectionItems",
        "kind": "LinkedField",
        "name": "collectionItems",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectionId",
            "storageKey": null
          },
          (v1/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseCollectionContentItems",
            "kind": "LinkedField",
            "name": "contentItems",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentId",
                "storageKey": null
              },
              (v1/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "courseUserCourseUpdateMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "courseUserCourseUpdateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "c65e42b7dd9fd01c86f2e8a4a91b4190",
    "id": null,
    "metadata": {},
    "name": "courseUserCourseUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation courseUserCourseUpdateMutation(\n  $input: UpdateUserCourseInput!\n) {\n  userCourseUpdate(input: $input) {\n    id\n    name\n    startDate\n    completedAt\n    collectionItems {\n      collectionId\n      name\n      index\n      startDate\n      completedAt\n      contentItems {\n        contentId\n        name\n        index\n        startDate\n        completedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "05dd29fdd54114c4651e76d1aa1e39d9";

export default node;

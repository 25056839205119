/**
 * @generated SignedSource<<2f2833907b806eb6364e282899bfdfde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteUsersInput = {
  emails: ReadonlyArray<string>;
};
export type accountInviteUsersMutation$variables = {
  connections: ReadonlyArray<string>;
  input: InviteUsersInput;
};
export type accountInviteUsersMutation$data = {
  readonly inviteUsers: {
    readonly accountUserInvitations: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"accountUserInvitationFragment">;
    }>;
  };
};
export type accountInviteUsersMutation = {
  response: accountInviteUsersMutation$data;
  variables: accountInviteUsersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountInviteUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AccountUserInvitationsPayload",
        "kind": "LinkedField",
        "name": "inviteUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountUserInvitation",
            "kind": "LinkedField",
            "name": "accountUserInvitations",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "accountUserInvitationFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "accountInviteUsersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AccountUserInvitationsPayload",
        "kind": "LinkedField",
        "name": "inviteUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountUserInvitation",
            "kind": "LinkedField",
            "name": "accountUserInvitations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "accountUserInvitations",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "Edge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20e8acd9a8d4c9121b86087e7c11d72a",
    "id": null,
    "metadata": {},
    "name": "accountInviteUsersMutation",
    "operationKind": "mutation",
    "text": "mutation accountInviteUsersMutation(\n  $input: InviteUsersInput!\n) {\n  inviteUsers(input: $input) {\n    accountUserInvitations {\n      ...accountUserInvitationFragment\n      id\n    }\n  }\n}\n\nfragment accountUserInvitationFragment on AccountUserInvitation {\n  id\n  externalId\n  email\n  createdAt\n}\n"
  }
};
})();

(node as any).hash = "6429eac4e98746f69ae7344b1561033e";

export default node;

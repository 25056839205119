import RelayEnvironment from 'lib/utils/relay';
import { loadQuery } from 'react-relay';
import { AUTH_VALIDATE_INVITE_USER_QUERY } from 'services/auth/auth.services';
import { authValidateInviteUserQuery } from 'services/auth/__generated__/authValidateInviteUserQuery.graphql';

export const TeamInvitePreloadData = () => {
  const query = new URLSearchParams(window.location.search);
  const inviteToken = query.get('token') ?? '';
  const email = query.get('email')?.trim().replace(' ', '+') ?? '';

  return {
    inviteToken,
    email,
    validateInviteUserQueryRef: loadQuery<authValidateInviteUserQuery>(
      RelayEnvironment,
      AUTH_VALIDATE_INVITE_USER_QUERY,
      {
        input: {
          token: inviteToken,
        },
        skip: !inviteToken,
      },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type TeamInvitePreloadDataType = ReturnType<typeof TeamInvitePreloadData>;

/**
 * @generated SignedSource<<0c86c4f108ad6549516ba0541495969f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValidateForgotUserPasswordInput = {
  token: string;
};
export type authValidateForgotUserPasswordQuery$variables = {
  input: ValidateForgotUserPasswordInput;
};
export type authValidateForgotUserPasswordQuery$data = {
  readonly validateForgotUserPassword: boolean;
};
export type authValidateForgotUserPasswordQuery = {
  response: authValidateForgotUserPasswordQuery$data;
  variables: authValidateForgotUserPasswordQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "validateForgotUserPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authValidateForgotUserPasswordQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authValidateForgotUserPasswordQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ab212d517d1d637ee8d2c5f3a3b84fb8",
    "id": null,
    "metadata": {},
    "name": "authValidateForgotUserPasswordQuery",
    "operationKind": "query",
    "text": "query authValidateForgotUserPasswordQuery(\n  $input: ValidateForgotUserPasswordInput!\n) {\n  validateForgotUserPassword(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "99cb127c3286e5bff2985ebbc116ca96";

export default node;

import graphql from 'babel-plugin-relay/macro';

/**
 * Fragments
 */

export const COURSE_COLLECTION_FRAGMENT = graphql`
  fragment courseCollectionFragment on Collection @relay(plural: true) {
    id
    index
    name
    imageUrl
    currentUserPercentCompleted
    collectionContents {
      id
      index
      content {
        isVisible
        id
        name
      }
    }
  }
`;

export const COURSE_CREATOR_FRAGMENT = graphql`
  fragment courseCreatorFragment on Creator {
    id
    name
    profileImageUrl
  }
`;

export const COURSE_FRAGMENT = graphql`
  fragment courseFragment on Course {
    id
    name
    description
    imageUrl
    collections {
      ...courseCollectionFragment
    }
    creator {
      ...courseCreatorFragment
    }
    categories {
      name
    }
  }
`;

export const COURSE_USER_COURSE_FRAGMENT = graphql`
  fragment courseUserCoursesFragment on UserCourse {
    id
    completedAt
    startDate
    percentCompleted
    course {
      id
      name
      description
      imageUrl
      categories {
        name
      }
    }
  }
`;

export const COURSE_USER_COURSES_PAGINATION_FRAGMENT = graphql`
  fragment courseUserCoursesPaginationFragment on Query @refetchable(queryName: "CoursesUserCoursesRefetchQuery") {
    userCourses(first: $first, after: $after, ordering: $ordering, filter: $filter)
      @connection(key: "CourseUserCoursesPaginationFragment_userCourses") {
      __id
      count
      edges {
        __id
        node {
          ...courseUserCoursesFragment
        }
      }
    }
  }
`;

/**
 * Queries
 */
export const COURSE_QUERY = graphql`
  query courseQuery($id: ID!) {
    course(id: $id) {
      ...courseFragment
    }
  }
`;

export const COURSE_USER_COURSES_QUERY = graphql`
  query courseUserCoursesQuery(
    $first: Int!
    $after: String
    $ordering: [UserCourseOrdering!]
    $filter: UserCourseFilter
  ) {
    ...courseUserCoursesPaginationFragment
  }
`;

export const COURSE_MENU_ITEMS_QUERY = graphql`
  query courseMenuItemsQuery($id: ID!) {
    courseMenuItems(id: $id) {
      id
      name
      nextContentId
      nextCollectionId
      percentCompleted
      startDate
      completedAt
      collectionItems {
        collectionId
        name
        index
        startDate
        completedAt
        contentItems {
          name
          contentId
          index
          startDate
          completedAt
        }
      }
    }
  }
`;

/**
 * Mutations
 */
export const COURSE_USER_COURSE_UPDATE_MUTATION = graphql`
  mutation courseUserCourseUpdateMutation($input: UpdateUserCourseInput!) {
    userCourseUpdate(input: $input) {
      id
      name
      startDate
      completedAt
      collectionItems {
        collectionId
        name
        index
        startDate
        completedAt
        contentItems {
          contentId
          name
          index
          startDate
          completedAt
        }
      }
    }
  }
`;

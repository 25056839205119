import RelayEnvironment from 'lib/utils/relay';
import { loadQuery } from 'react-relay/hooks';
import { getAccountId, getUserId } from 'lib/utils/authentication';
import { USER_QUERY, USER_LEVEL_HISTORIES_QUERY } from 'services/user/user.services';
import { userQuery } from 'services/user/__generated__/userQuery.graphql';
import { userLevelHistoriesQuery } from 'services/user/__generated__/userLevelHistoriesQuery.graphql';
import { SORT_ORDER } from 'types/common.d';

export const DefaultPreloadData = () => {
  const userId = getUserId();
  const accountId = getAccountId() ?? '';

  return {
    accountId,
    userQueryRef: loadQuery<userQuery>(
      RelayEnvironment,
      USER_QUERY,
      { id: userId ?? '', skip: !userId },
      { fetchPolicy: 'store-or-network' },
    ),
    userLevelHistoriesQueryRef: loadQuery<userLevelHistoriesQuery>(
      RelayEnvironment,
      USER_LEVEL_HISTORIES_QUERY,
      {
        first: 1,
        ordering: [{ sort: 'CREATED_AT', direction: SORT_ORDER.DESC }],
        filter: { where: { user: { id: { equalTo: userId } } } },
        skip: !userId,
      },
      { fetchPolicy: 'store-or-network' },
    ),
  };
};

export type DefaultPreloadDataType = ReturnType<typeof DefaultPreloadData>;

/**
 * @generated SignedSource<<aa043c68da65d5fa9956150993d1ebde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type ContentQuestionType = "match_choice" | "multiple_choice" | "single_choice" | "text" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type contentsPaginationFragment$data = {
  readonly contents?: {
    readonly __id: string;
    readonly count: number | null;
    readonly edges: ReadonlyArray<{
      readonly __id: string;
      readonly node: {
        readonly collectionContents: ReadonlyArray<{
          readonly userCourseCollectionContents: ReadonlyArray<{
            readonly answers: ReadonlyArray<{
              readonly answer: string | null;
              readonly id: string;
              readonly label: string | null;
              readonly multipleChoiceAnswers: ReadonlyArray<{
                readonly id: string;
                readonly label: string;
              } | null> | null;
              readonly singleChoiceAnswer: {
                readonly id: string;
                readonly label: string;
              } | null;
              readonly type: ContentQuestionType;
            } | null> | null;
          }>;
        }>;
        readonly " $fragmentSpreads": FragmentRefs<"contentFragment" | "contentInlineFragment">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "contentsPaginationFragment";
};
export type contentsPaginationFragment$key = {
  readonly " $data"?: contentsPaginationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentsPaginationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "contents"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "filter"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "ordering"
    },
    {
      "kind": "RootArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ContentsRefetchQuery.graphql')
    }
  },
  "name": "contentsPaginationFragment",
  "selections": [
    {
      "condition": "skip",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "contents",
          "args": [
            {
              "kind": "Variable",
              "name": "filter",
              "variableName": "filter"
            },
            {
              "kind": "Variable",
              "name": "ordering",
              "variableName": "ordering"
            }
          ],
          "concreteType": "ContentConnection",
          "kind": "LinkedField",
          "name": "__ContentsPaginationFragment_contents_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Content",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "contentInlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mediaType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "imageUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "mediaUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "externalId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isVisible",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "points",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "length",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "contentFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CollectionContent",
                      "kind": "LinkedField",
                      "name": "collectionContents",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserCourseCollectionContent",
                          "kind": "LinkedField",
                          "name": "userCourseCollectionContents",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "UserCourseCollectionContentAnswer",
                              "kind": "LinkedField",
                              "name": "answers",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "type",
                                  "storageKey": null
                                },
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "answer",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ContentQuestionChoice",
                                  "kind": "LinkedField",
                                  "name": "multipleChoiceAnswers",
                                  "plural": true,
                                  "selections": (v3/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ContentQuestionChoice",
                                  "kind": "LinkedField",
                                  "name": "singleChoiceAnswer",
                                  "plural": false,
                                  "selections": (v3/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "686f10a6936a042e6f130e9bc740af02";

export default node;

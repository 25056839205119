/**
 * index.tsx
 *
 * This is the entry file for the application
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'lib/utils/error';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from 'serviceWorker';
import 'sanitize.css/sanitize.css';
import 'styles/__fonts.css';
import { ThemeProvider } from 'styled-components';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import RelayEnvironment from 'lib/utils/relay';

import { LoaderPukaUp } from 'app/components/LoaderPukaUp';
import * as amplitude from '@amplitude/analytics-browser';

import * as Sentry from '@sentry/react';
import { clarity } from 'react-microsoft-clarity';

import StyledTheme from 'styles/theme';

// Import root app
import App from 'app';

import { HelmetProvider } from 'react-helmet-async';

// Initialize languages
import i18nInit from 'locales/i18n';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';

import { LanguageEnum } from './types';
import { getUserId } from 'lib/utils/authentication';
import { getAccountId } from 'lib/utils/authentication';
import { TrackingEvents } from 'lib/constants/tracking';
import { trackAccountId, trackUserId } from 'hooks/useTracking/useTracking';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
  Component: typeof App;
}

const customConf = {
  mediaQuery: 'only screen',
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 90, // 1440px
    xl: 120, // 1920px
  },
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2,
  },
  paddingWidth: {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2,
  },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 84, // max-width: 1440px
    xl: 84, // max-width: 1440px
  },
};

enum Environment {
  Development = 'development',
  Preview = 'preview',
  Production = 'production',
}
const isProduction = [process.env.NODE_ENV, process.env.NEXT_PUBLIC_VERCEL_ENV, process.env.VERCEL_ENV].includes(
  Environment.Production,
);

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

function initializeGoogleAnalytics(): void {
  window.dataLayer = window.dataLayer || [];

  window.gtag = function gtag(...args: any[]): void {
    window.dataLayer.push(...args);
  };

  if (isProduction) {
    window.gtag('js', new Date());

    window.gtag('config', 'G-5XVZ3B6B1J', {
      user_id: getUserId(),
      account_id: getAccountId(),
    });

    const accountId = getAccountId();
    if (accountId) {
      trackAccountId(accountId);
    }

    const userId = getUserId();
    if (userId) {
      trackUserId(userId);
    }
  }
}

const clarityInit = () => {
  if (isProduction) {
    try {
      const userId = getUserId() ?? undefined;
      const accountId = getAccountId();

      clarity.init(process.env.REACT_APP_CLARITY_ID ?? '');
      if (clarity.hasStarted()) {
        if (userId) {
          clarity.identify(userId, {
            ACCOUNT_ID: accountId,
          });
        }
      }
    } catch (e) {
      // TODO: Throw sentry error
      console.error(e);
    }
  }
};

const amplitudeInit = () => {
  if (isProduction) {
    const userId = getUserId() ?? undefined;
    const accountId = getAccountId();
    try {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_ID ?? 'f0db6f3bac0654220bf332ac2f76f1f5', userId, {
        defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
      });
      if (accountId) {
        amplitude.setGroup('COMPANY_ID', accountId);
        amplitude.setGroup(TrackingEvents.ACCOUNT_ID, accountId);
      }
    } catch (e) {
      // TODO: Throw sentry error
      console.error(e);
    }
  }
};

export const sentryInit = () => {
  const tracingOrigins: string[] = [(process.env.REACT_APP_API_BASE_URL ?? '').replace(/https{0,1}:\/\//, '')].filter(
    (origin: string): boolean => !!origin,
  );
  if (isProduction) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [new Sentry.BrowserTracing({ tracingOrigins }), new Sentry.Replay()],
      tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
      environment:
        process.env.VERCEL_ENV ?? process.env.NEXT_PUBLIC_VERCEL_ENV ?? process.env.NODE_ENV ?? 'development',
      // release: process.env.SENTRY_RELEASE,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
    });
  }
};

const ConnectedApp = ({ Component }: Props) => {
  const [defaultLanguage] = useLocalStorage(LanguageEnum.USER_LANGUAGE, 'en');
  i18nInit(defaultLanguage);

  clarityInit();
  initializeGoogleAnalytics();
  amplitudeInit();
  sentryInit();

  const handleResetError = () => {
    // Perform any necessary cleanup or reset operations
    // You can reload the page or navigate to a specific route, for example
    window.location.reload();
  };
  /**
   * Return
   */
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <ErrorFallback error={error} resetErrorBoundary={handleResetError} />}
    >
      <ThemeProvider theme={{ awesomegrid: customConf }}>
        <ThemeProvider theme={StyledTheme}>
          <RelayEnvironmentProvider environment={RelayEnvironment}>
            <React.Suspense fallback={<LoaderPukaUp />}>
              <HelmetProvider>
                {/* <React.StrictMode> */}
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <Component />
                </ErrorBoundary>
                {/* </React.StrictMode> */}
              </HelmetProvider>
            </React.Suspense>
          </RelayEnvironmentProvider>
        </ThemeProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};
const render = (Component: typeof App) => {
  return ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./app', './locales/i18n'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./app').App;
    render(App);
  });
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

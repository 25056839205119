/**
 * @generated SignedSource<<18c53109e30cbbdca4d37884c289c0f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValidateVerifyUserEmailInput = {
  token: string;
};
export type authValidateVerifyUserEmailQuery$variables = {
  input: ValidateVerifyUserEmailInput;
  skip: boolean;
};
export type authValidateVerifyUserEmailQuery$data = {
  readonly validateVerifyUserEmail?: {
    readonly email: string;
  };
};
export type authValidateVerifyUserEmailQuery = {
  response: authValidateVerifyUserEmailQuery$data;
  variables: authValidateVerifyUserEmailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "VerifyResponse",
        "kind": "LinkedField",
        "name": "validateVerifyUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "authValidateVerifyUserEmailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "authValidateVerifyUserEmailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9e6249542fcdb0f5c13fea2b5acb07d",
    "id": null,
    "metadata": {},
    "name": "authValidateVerifyUserEmailQuery",
    "operationKind": "query",
    "text": "query authValidateVerifyUserEmailQuery(\n  $input: ValidateVerifyUserEmailInput!\n  $skip: Boolean!\n) {\n  validateVerifyUserEmail(input: $input) @skip(if: $skip) {\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "37dcf9d1c2fdf53dc6d30ac5b3fb10ed";

export default node;

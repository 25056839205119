import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const GlobalStyle = createGlobalStyle`
    html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, fieldset, form, label, legend, textarea, table, caption, tbody, tfoot, thead, tr, th, td {
      margin: 0;
      padding: 0;
      border: 0;
      font-weight: inherit;
      font-style: inherit;
      font-size: 100%;
      font-family: inherit;
      line-height: 24px;
    }

    html,
    body {
      height: 100%;
      width: 100%;
      line-height: 1.5;
      color: ${themeGet('colors.blacks.1')};
      font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      overflow: hidden;
      cursor: auto;
    }

    body.fontLoaded {
      font-family: 'OpenSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: ${themeGet('fontSizes.1')}px;
    }

    #root {
      background-color: #FFF;
      font-size: ${themeGet('fontSizes.1')}px;
      height: 100%;
      width: 100%;
    }

    div {
      font-size: ${themeGet('fontSizes.1')}px;
    }

    ::selection {
      color: ${themeGet('colors.blacks.1')};
      background: ${themeGet('colors.greens.5')};
    }

    p,
    label {
      line-height: 1.5em;
      font-size: ${themeGet('fontSizes.1')}px;
    }

    a {
      transition: color 125ms ease-in-out;
      color: ${themeGet('colors.blacks.1')};
      text-decoration: none;
      font-size: ${themeGet('fontSizes.1')}px;
      cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      line-height: 1.25;
      outline: 0;
    }

    h1 {
      font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      font-weight: 400;
      line-height: 1.1;
    }

    h2 {
      font-size: 1.8rem;;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
    }

    button {
      background:none;
      border:none;
    }

    ::placeholder {
      font-size: 16px !important;
      color: #bfbfbf;
    }

    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 16px !important;
      color: #bfbfbf;
    }
    input::-moz-placeholder { /* Firefox 19+ */
      font-size: 16px !important;
      color: #bfbfbf;
    }
    input:-ms-input-placeholder { /* IE 10+ */
      font-size: 16px !important;
      color: #bfbfbf;
    }
    input:-moz-placeholder { /* Firefox 18- */
      font-size: 16px !important;
      color: #bfbfbf;
    }

    ::placeholder, .ant-select-selection-placeholder {
      font-size: 16px !important;
      color: #bfbfbf;
      opacity: 1;
    }

    :not(body) {
      background-repeat: no-repeat;
      outline: none;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #b2b2b2;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  /**
  * Toastify
  */
 .Toastify__toast {
  box-shadow: 0 2px 10px rgba(0,0,0,.1);
  border: 1px solid ${themeGet('colors.greys.3')};
  border-radius: ${themeGet('radii.2')}px;
  min-height: 40px;
  font-family: inherit;
  font-size: ${themeGet('fontSizes.1')}px;
  padding: ${themeGet('space.3')}px;
 }
 .Toastify__close-button {
   height: 32px;
 }
 .Toastify__toast--success {
   background: ${themeGet('colors.blacks.1')};;
   border: 1px solid ${themeGet('colors.blacks.1')};;
   padding: ${themeGet('spaces.2')}px;
 }
 .Toastify__toast--default {
   color: ${themeGet('colors.blacks.1')};
   padding: ${themeGet('spaces.2')}px;
 }
 .Toastify__toast--error {
   background: ${themeGet('colors.reds.2')};
   padding: ${themeGet('spaces.2')}px;
 }
 /**
 * Tooltip
 */
.tooltip {
  font-size: ${themeGet('fontSizes.0')}px !important;
  max-width: 300px;
  border: 1px solid ${themeGet('colors.greys.3')} !important;
  color: ${themeGet('colors.greys.0')} !important;

  &.place-right {
    &:before {
      border-right: 8px solid ${themeGet('colors.greys.3')} !important;
    }
  }
  &.place-bottom {
    &:before {
      border-bottom: 8px solid ${themeGet('colors.greys.3')} !important;
    }
  }
  &.place-left {
    &:before {
      border-left: 8px solid ${themeGet('colors.greys.3')} !important;
    }
  }
  &.place-top {
    &:before {
      border-top: 8px solid ${themeGet('colors.greys.3')} !important;
    }
  }
  
  * {
    font-size: ${themeGet('fontSizes.0')}px !important;
  }

  p {
    margin-bottom: ${themeGet('space.2')}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
 /**
 * Popover Default
 */
 .react-tiny-popover-container {
    box-shadow: 0 0 0 1px #8898aa1a,0 15px 35px 0 #31315d1a,0 5px 15px 0 #00000014;
    border-radius: ${themeGet('radii.2')}px;
    z-index: 9;
 }
.popover-default {
    box-shadow: 0 2px 10px rgba(0,0,0,.1);
    min-width: 200px;
    border: 1px solid ${themeGet('colors.greys.3')};
 }

 /**
 * Popover List Menu
 */
 .popover-list-menu {
    box-shadow: ${themeGet('shadows.dropdown')};
    border-radius: ${themeGet('radii.2')}px;
    background: #FFF;
    padding: 0;
    min-width: 200px;
    z-index: 99999;
 }
 /**
 * Navbar Menu
  */
 .popover-navbar-menu {
    box-shadow: ${themeGet('shadows.dropdown')};
    border-radius: ${themeGet('radii.1')}px;
    background: #FFF;
    min-width: 200px;
    z-index: 99999;
    max-width: 450px;
    border: 1px solid ${themeGet('colors.greys.3')};
    margin-bottom:8px;
 }

  /**
   * Plyr
   */
   :root {
     --plyr-color-main: ${themeGet('colors.sands.3')};
     --plyr-control-spacing: 9px;
   }

   .plyr-holder {
     height: 100%;
   }
`;

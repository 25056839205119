import graphql from 'babel-plugin-relay/macro';

/**
 * Fragments
 */
export const ACCOUNT_FRAGMENT = graphql`
  fragment accountFragment on Account {
    id
    name
    businessLogoUrls
    externalId
  }
`;

export const ACCOUNT_USER_PROFILE_INVITATION_FRAGMENT = graphql`
  fragment accountUserInvitationFragment on AccountUserInvitation {
    id
    externalId
    email
    createdAt
  }
`;

export const ACCOUNT_USER_INVITATIONS_PAGINATION_FRAGMENT = graphql`
  fragment accountUserInvitationsPaginationFragment on Query
    @refetchable(queryName: "accountUserInvitationsPaginationQuery") {
    accountUserInvitations(first: $first, after: $after, ordering: $ordering, filter: $filter)
      @connection(key: "AccountUserInvitationsFragment_accountUserInvitations") {
      __id
      count
      edges {
        __id
        node {
          ...accountUserInvitationFragment
        }
      }
    }
  }
`;

/**
 * Queries
 */
export const ACCOUNT_QUERY = graphql`
  query accountQuery($id: ID!, $skip: Boolean!) {
    account(id: $id) @skip(if: $skip) {
      ...accountFragment
      userCount
    }
  }
`;

export const ACCOUNT_USERS_QUERY = graphql`
  query accountUsersQuery($first: Int!, $after: String, $ordering: [UserOrdering!]) {
    ...userAccountUsersPaginationFragment
  }
`;

export const ACCOUNT_BY_INVITE_TOKEN_QUERY = graphql`
  query accountByInviteTokenQuery($input: AccountByInviteTokenInput!, $skip: Boolean!) {
    accountByInviteToken(input: $input) @skip(if: $skip) {
      ...accountFragment
    }
  }
`;

export const ACCOUNT_USER_INVITATIONS_QUERY = graphql`
  query accountUserInvitationsQuery(
    $first: Int!
    $after: String
    $ordering: [AccountUserInvitationOrdering!]
    $filter: AccountUserInvitationFilter
  ) {
    ...accountUserInvitationsPaginationFragment
  }
`;

/**
 * Mutations
 */
export const ACCOUNT_UPDATE_MUTATION = graphql`
  mutation accountUpdateMutation($id: ID!, $input: AccountUpdateInput!) {
    updateAccount(id: $id, input: $input) {
      account {
        id
        name
      }
    }
  }
`;

export const ACCOUNT_INVITE_USERS_MUTATION = graphql`
  mutation accountInviteUsersMutation($input: InviteUsersInput!, $connections: [ID!]!) {
    inviteUsers(input: $input) {
      accountUserInvitations @prependNode(connections: $connections, edgeTypeName: "Edge") {
        ...accountUserInvitationFragment
      }
    }
  }
`;

export const ACCOUNT_DELETE_INVITE_USER_PROFILES_MUTATION = graphql`
  mutation accountDeleteAccountUserMutation($id: ID!, $connections: [ID!]!) {
    deleteAccountUserInvitation(id: $id) {
      id @deleteEdge(connections: $connections)
    }
  }
`;

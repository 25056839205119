export const colors = {
  blacks: ['#000000', '#1f2422', '#272E2B', '#333333'],
  blues: ['#254D71', '#36658F', '#4B7DAB', '#80A9CF', '#A9CAE8', '#EFF5FA'],
  camo: ['#5E6150', '#878A7', '#C1C1B7', '#DEE0D5'],
  greys: ['#838383', '#9B9B9B', '#C1C1C1', '#E0E0E0', '#F1F1F1', '#F9F9F9'],
  greens: ['#10311D', '#213E2C', '#375844', '#78AC8E', '#CEE1D6', '#EEF5F0'],
  // purples: ['#706B97', '#8E88B5', '#A8A3CB', '#C8C4E5', '#DFDCF3', '#F1F0F7'],
  oranges: ['#8E4319', '#AA5626', '#C46936', '#E68E5C', '#F6CDB6', '#F9F0EB'],
  olives: ['#80925A', '#97AA6F', '#ABBD83', '#C7D7A5', '#E2EBCD', '#F4F6EF'],
  reds: ['#90342A', '#B2493E', '#CC5F53', '#E87F74', '#F89F96', '#FFE7E4'],
  yellows: ['#8B601F', '#B9873D', '#D9A454', '#F0BE72', '#FEECCF', '#FBF6EE'],
  sands: ['#A3988A', '#DAD6D1', '#F5F1E9', '#F9F7F2', '#FBF9F6', '#FEFDFC'],
  teals: ['#4A5F63', '#50737A', '#8EA2A6', '#EAECEC'],
  whites: ['#FFF', '#F9F9F9'],
  aquas: ['#2bd2cf', '#40d7d4', '#55dbd9', '#6be0dd', '#80e4e2', '#95e9e7'],
  purples: ['#8d6aae', '#9879b6', '#a488be', '#af97c6', '#bba6ce', '#c6b5d7'],
};

/**
 * @generated SignedSource<<3e96816f99be580a4b2a32b1785b3e59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type courseCollectionFragment$data = ReadonlyArray<{
  readonly collectionContents: ReadonlyArray<{
    readonly content: {
      readonly id: string;
      readonly isVisible: any | null;
      readonly name: string;
    };
    readonly id: string;
    readonly index: number | null;
  }>;
  readonly currentUserPercentCompleted: number;
  readonly id: string;
  readonly imageUrl: string;
  readonly index: number | null;
  readonly name: string;
  readonly " $fragmentType": "courseCollectionFragment";
}>;
export type courseCollectionFragment$key = ReadonlyArray<{
  readonly " $data"?: courseCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"courseCollectionFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "courseCollectionFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserPercentCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionContent",
      "kind": "LinkedField",
      "name": "collectionContents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Content",
          "kind": "LinkedField",
          "name": "content",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isVisible",
              "storageKey": null
            },
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Collection",
  "abstractKey": null
};
})();

(node as any).hash = "1fb0cff17474be860e1e25f009fd7130";

export default node;

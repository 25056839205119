import { Environment, RecordSource, Store } from 'relay-runtime';
import { publicService } from './request';
import {
  RelayNetworkLayer,
  urlMiddleware,
  // batchMiddleware,
  // loggerMiddleware,
  errorMiddleware,
  // perfMiddleware,
  authMiddleware,
  cacheMiddleware,
  progressMiddleware,
  uploadMiddleware,
} from 'react-relay-network-modern';
import * as Sentry from '@sentry/react';
import { getRequestId } from 'lib/utils/request';

const isDev = process.env.NODE_ENV !== 'production' || false;
const network = new RelayNetworkLayer(
  [
    cacheMiddleware({
      size: 100,
      ttl: 900000,
    }),
    urlMiddleware({
      url: req => Promise.resolve(`${process.env.REACT_APP_API_BASE_URL}/graphql`),
    }),
    // batchMiddleware({
    //   batchUrl: requestList => Promise.resolve(`${process.env.REACT_APP_API_BASE_URL}/query/batch`),
    //   batchTimeout: 10,
    // }),

    // Logging
    // isDev ? loggerMiddleware() : null,
    isDev ? errorMiddleware() : null,
    // isDev ? perfMiddleware() : null,

    // retryMiddleware({
    //   fetchTimeout: 15000,
    //   retryDelays: attempt => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
    //   beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
    //     if (attempt > 10) abort();
    //     window.forceRelayRetry = forceRetry;
    //   },
    //   statusCodes: [500, 503, 504],
    // }),
    authMiddleware({
      token: () => window.localStorage.getItem('token') || '',
      allowEmptyToken: true,
      tokenRefreshPromise: async req => {
        return publicService('/graphql', {
          method: 'post',
          data: {
            query: `mutation refreshToken {
              refreshToken {
                accessToken
              }
            }`,
          },
        })
          .then(res => {
            const token = res.data.refreshToken.accessToken;
            window.localStorage.setItem('token', token);
            return token;
          })
          .catch(err => {
            window.localStorage.removeItem('token');
            window.localStorage.setItem('logout', Date.now().toString());
            window.open('/login', '_self');
          });
      },
    }),
    progressMiddleware({
      onProgress: (current, total) => {
        console.log('Downloaded: ' + current + ' B, total: ' + total + ' B');
      },
    }),
    uploadMiddleware(),
    next => async req => {
      (req as any).credentials = 'include';
      const requestId = getRequestId();
      Sentry.configureScope(scope => {
        scope.setTag('x_request_id', requestId);
      });
      req.fetchOpts.headers['x-request-id'] = requestId;
      const res = await next(req);
      return res;
    },
  ],
  { noThrow: true },
);

// Create a custom function to handle fetch errors
// const handleFetchError = (error: Error) => {
//   // Check if the error message indicates a fetch error
//   if (error.message === 'Failed to fetch') {
//     // Clear the token from localStorage
//     window.localStorage.removeItem('token');
//   }
// };

const source = new RecordSource();
const store = new Store(source);
const environment = new Environment({ network, store });

// Add an event listener to handle uncaught fetch errors
// window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
//   const error = event.reason as Error;
//   handleFetchError(error);
// });

export default environment;

/**
 * Dynamically load an external script
 * and know when it is loaded.
 */
import { useEffect, useState } from 'react';

type Options = {
  crossOrigin?: boolean;
  scriptId?: string;
  scriptType?: string;
  onLoad?: () => void;
};

type State = {
  error: boolean;
  loaded: boolean;
};

const cache: string[] = [];
export default function useScript(source: string, options: Options = {}) {
  const [state, setState] = useState<State>({
    error: false,
    loaded: false,
  });

  useEffect(() => {
    if (!cache.includes(source)) {
      cache.push(source);

      const script: HTMLScriptElement = document.createElement('script');
      script.src = source;
      script.async = true;
      if (options.crossOrigin) script.crossOrigin = '';
      if (options.scriptId) script.id = options.scriptId;
      if (options.scriptType) script.type = options.scriptType;

      script.onload = () => {
        options.onLoad && options.onLoad();
        setState({
          error: false,
          loaded: true,
        });
      };

      script.onerror = () => {
        script.remove();

        // remove from cache
        const index = cache.indexOf(source);
        if (index >= 0) {
          cache.splice(index, 1);
        }

        setState({
          loaded: false,
          error: true,
        });
      };
      document.body.appendChild(script);
    } else {
      setState({ error: false, loaded: true });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [source]);

  return [state.loaded, state.error];
}

/**
 * @generated SignedSource<<7f0d611800159d8cf67fa41bc5598773>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ContentQuestionType = "match_choice" | "multiple_choice" | "single_choice" | "text" | "%future added value";
export type contentQuery$variables = {
  id: string;
};
export type contentQuery$data = {
  readonly content: {
    readonly collectionContents: ReadonlyArray<{
      readonly collection: {
        readonly id: string;
      };
      readonly content: {
        readonly id: string;
      };
      readonly id: string;
      readonly index: number | null;
    }>;
    readonly createdAt: any | null;
    readonly creator: {
      readonly coverImageUrl: string | null;
      readonly id: string;
      readonly name: string;
      readonly profileImageUrl: string | null;
    };
    readonly deletedAt: any | null;
    readonly externalId: string;
    readonly id: string;
    readonly imageUrl: string | null;
    readonly isVisible: any | null;
    readonly length: number | null;
    readonly mediaType: string;
    readonly mediaUrl: string | null;
    readonly name: string;
    readonly points: number;
    readonly prismicContent: any | null;
    readonly questions: ReadonlyArray<{
      readonly answerFeedback: {
        readonly correct: {
          readonly body: string | null;
          readonly header: string | null;
        } | null;
        readonly incorrect: {
          readonly body: string | null;
          readonly header: string | null;
        } | null;
      } | null;
      readonly choiceAnswers: ReadonlyArray<{
        readonly id: string;
        readonly index: number;
        readonly isCorrect: boolean | null;
      } | null> | null;
      readonly id: string;
      readonly index: number;
      readonly label: string | null;
      readonly type: ContentQuestionType;
    } | null> | null;
    readonly updatedAt: any | null;
  };
};
export type contentQuery = {
  response: contentQuery$data;
  variables: contentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "body",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "header",
    "storageKey": null
  }
],
v5 = [
  (v1/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Content",
    "kind": "LinkedField",
    "name": "content",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mediaType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "prismicContent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mediaUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVisible",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "points",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "length",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentQuestion",
        "kind": "LinkedField",
        "name": "questions",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentQuestionChoice",
            "kind": "LinkedField",
            "name": "choiceAnswers",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isCorrect",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentQuestionAnswerFeedback",
            "kind": "LinkedField",
            "name": "answerFeedback",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnswerFeedback",
                "kind": "LinkedField",
                "name": "incorrect",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AnswerFeedback",
                "kind": "LinkedField",
                "name": "correct",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Creator",
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profileImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coverImageUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CollectionContent",
        "kind": "LinkedField",
        "name": "collectionContents",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Content",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Collection",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contentQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contentQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "cd10e3c7a6e10c63594c7831af7fae57",
    "id": null,
    "metadata": {},
    "name": "contentQuery",
    "operationKind": "query",
    "text": "query contentQuery(\n  $id: ID!\n) {\n  content(id: $id) {\n    id\n    name\n    mediaType\n    externalId\n    prismicContent\n    imageUrl\n    mediaUrl\n    isVisible\n    points\n    createdAt\n    updatedAt\n    deletedAt\n    length\n    questions {\n      id\n      index\n      type\n      label\n      choiceAnswers {\n        id\n        index\n        isCorrect\n      }\n      answerFeedback {\n        incorrect {\n          body\n          header\n        }\n        correct {\n          body\n          header\n        }\n      }\n    }\n    creator {\n      id\n      name\n      profileImageUrl\n      coverImageUrl\n    }\n    collectionContents {\n      id\n      index\n      content {\n        id\n      }\n      collection {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b4e7594c2141d7366d82a5b7588bd32";

export default node;

/**
 * @generated SignedSource<<7448dc38d6b4964ced36aa1969c82aa0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userNameFragment$data = {
  readonly firstName: string;
  readonly id: string;
  readonly lastName: string;
  readonly phone: string | null;
  readonly photoUrls: any | null;
  readonly " $fragmentType": "userNameFragment";
};
export type userNameFragment$key = {
  readonly " $data"?: userNameFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userNameFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userNameFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoUrls",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "fab6681f9ad13d0eb76b3f0bf302f3f0";

export default node;

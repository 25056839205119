/**
 * An entity ID
 */
export type ID = string | number;

// Global
declare global {
  interface Window {
    analytics: any;
    forceRelayRetry: any;
    feedback: any;
    attachEvent: any;
    zE: any;
  }
}

// Language
export enum LanguageEnum {
  USER_LANGUAGE = 'user-lang',
}

export enum LocaleEnum {
  EN = 'en',
}

// Location
export type Location = {
  pathname: string;
};

// User role
export enum UserRole {
  Admin = 'admin',
  User = 'user',
}

// User activity
export type UserActivityHistoryMeta = {
  text?: string;
  achievementType?: string;
};
